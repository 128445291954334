import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-3">
        <div class="row mb-4">
          <div class="col-md-12">
            <h1>Facturación</h1>
          </div>
          <div class="col-12 d-block d-sm-none">
            <app-receipt-breakdown
              [bfc]="bfc"
              [additionalConcepts]="additionalConcepts"
              [interests]="settlement.interests"
            ></app-receipt-breakdown>
          </div>
          <div class="col-12 col-md-6">
            <div class="card rounded-4 shadow mb-3 d-none d-sm-block">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-3">Transacción</h3>
                  </div>
                  <div class="col-md-2">
                    <div class="badge bg-light py-2 px-2 me-2 border text-center float-start">
                      <img [src]="cardType(transaction?.payment_method_brand)" alt="Card image cap" width="44"> 
                    </div>
                  </div>
                  <div class="col-md-8 ms-2">
                    <h5 class="mb-0"><i># {{transaction?.transaction_id}}</i></h5>
                    <p class="mb-0"><small>Fecha pago: {{transaction?.created_at | date: 'dd/MM/yy'}}</small></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <!-- h3>Facturación</!-->
                    <div class="alert alert-info d-none d-sm-block">
                      <p class="mb-0">Recuerda de llenar la información de facturación correctamente, ya que no se podrá modificar posteriormente.</p>
                    </div>
                    <div class="alert alert-warning d-none d-sm-block">
                      <p class="mb-0">Únicamente se factura el consumo generado en el mes. No se consideran los adeudos y recargos.</p>
                    </div>
                  </div>
                  <form [formGroup]="invoiceForm" (ngSubmit)="submit()">
                    <div class="col-md-12">
                      <div class="form-group required mb-2 mb-sm-4 mt-0 mt-sm-3">
                        <label for="rfc" class="form-label ms-2">RFC</label>
                        <input type="text" class="form-control form-control-lg" formControlName="rfc">
                        <div *ngIf="ufc['rfc'].touched && ufc['rfc'].invalid">
                          <div *ngIf="ufc['rfc'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-2 mb-sm-4 mt-0 mt-sm-3">
                        <label for="razon_social" class="form-label ms-2">Razón social</label>
                        <input type="text" class="form-control form-control-lg" formControlName="razon_social">
                        <div *ngIf="ufc['razon_social'].touched && ufc['razon_social'].invalid">
                          <div *ngIf="ufc['razon_social'].errors?.['required']" class="text-danger text-sm">La razón social es requerida.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-2 mb-sm-4 mt-0 mt-sm-3">
                        <label for="zip_code" class="form-label ms-2">Código Postal</label>
                        <input type="text" class="form-control form-control-lg" formControlName="zip_code">
                        <div *ngIf="ufc['zip_code'].touched && ufc['zip_code'].invalid">
                          <div *ngIf="ufc['zip_code'].errors?.['required']" class="text-danger text-sm">El código postal es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-2 mb-sm-4 mt-0 mt-sm-3">
                        <label for="regimen_fiscal" class="form-label ms-2">Régimen fiscal</label>
                        <select class="form-select form-select-lg" formControlName="regimen_fiscal">
                          <option *ngFor="let regimen of regimenes" [value]="regimen.id">{{regimen.id}} - {{regimen.name}}</option>
                        </select>
                        <div *ngIf="ufc['regimen_fiscal'].touched && ufc['regimen_fiscal'].invalid">
                          <div *ngIf="ufc['regimen_fiscal'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-2 mb-sm-4 mt-0 mt-sm-3">
                        <label for="uso_cfdi" class="form-label ms-2">Uso CFDI</label>
                        <select class="form-select form-select-lg" formControlName="uso_cfdi">
                          <option *ngFor="let uso of usosCFDI" [value]="uso.id">{{uso.id}} - {{uso.name}}</option>
                        </select>
                        <div *ngIf="ufc['uso_cfdi'].touched && ufc['uso_cfdi'].invalid">
                          <div *ngIf="ufc['uso_cfdi'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group mb-0 mt-5 text-end">
                        <!-- button type="button" class="btn btn-link me-2" routerLink="/tenant/payments/{{user.id}}">Cancelar</!-->
                        <button type="submit" class="btn btn-success btn-lg rounded-5 px-4" [disabled]="loading">
                          <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                          <fa-icon [icon]="icons.faSave" class="me-2" *ngIf="!loading"></fa-icon>
                          Generar factura
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 offset-0 offset-md-1 d-none d-sm-block">
            <app-receipt-reads [bfc]="bfc" [medidor]="medidor" [prev_medidor]="prev_medidor"></app-receipt-reads>
            <app-receipt-breakdown
              [bfc]="bfc"
              [additionalConcepts]="additionalConcepts"
              [interests]="settlement.interests"
            ></app-receipt-breakdown>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceCreateComponent {
  ux:any = localStorage.getItem('user')
  user: any = this.ux != null ? JSON.parse(this.ux) : null;
  profile: any = {};

  settlement: any = {};
  building: any = {};
  unit: any = {};
  loading: boolean = false;

  conceptos: any = [];
  additionalConcepts: any = [];
  serie: string = 'MCON';
  formapago: string = '';
  complemento: any = {};
  today: Date = new Date();

  medidor: any = null;
  prev_medidor: any = null;
  
  regimenes: any = [
      { id: 601, name: 'General de Ley Personas Morales' },
      { id: 603, name: 'Personas Morales con Fines no Lucrativos' },
      { id: 605, name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
      { id: 606, name: 'Arrendamiento' },
      { id: 607, name: 'Régimen de Enajenación o Adquisición de Bienes' },
      { id: 608, name: 'Demás ingresos' },
      { id: 609, name: 'Consolidación' },
      { id: 610, name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
      { id: 611, name: 'Ingresos por Dividendos (socios y accionistas)' },
      { id: 612, name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
      { id: 614, name: 'Ingresos por intereses' },
      { id: 615, name: 'Régimen de los ingresos por obtención de premios' },
      { id: 616, name: 'Sin obligaciones fiscales' },
      { id: 620, name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
      { id: 621, name: 'Incorporación Fiscal' },
      { id: 622, name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
      { id: 623, name: 'Opcional para Grupos de Sociedades' },
      { id: 624, name: 'Coordinados' },
      { id: 625, name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
      { id: 626, name: 'Régimen Simplificado de Confianza' },
      { id: 628, name: 'Hidrocarburos' },
      { id: 629, name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
      { id: 630, name: 'Enajenación de acciones en bolsa de valores' }
    ];
  
    usosCFDI: any = [
      { id: 'G01', name: 'Adquisición de mercancías.' },
      { id: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
      { id: 'G03', name: 'Gastos en general.' },
      { id: 'I01', name: 'Construcciones.' },
      { id: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
      { id: 'I03', name: 'Equipo de transporte.' },
      { id: 'I04', name: 'Equipo de computo y accesorios.' },
      { id: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
      { id: 'I06', name: 'Comunicaciones telefónicas.' },
      { id: 'I07', name: 'Comunicaciones satelitales.' },
      { id: 'I08', name: 'Otra maquinaria y equipo.' },
      { id: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
      { id: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
      { id: 'D03', name: 'Gastos funerales.' },
      { id: 'D04', name: 'Donativos.' },
      { id: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
      { id: 'D06', name: 'Aportaciones voluntarias al SAR.' },
      { id: 'D07', name: 'Primas por seguros de gastos médicos.' },
      { id: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
      { id: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
      { id: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
      { id: 'S01', name: 'Sin efectos fiscales.' },
      { id: 'CP01', name: 'Pagos' },
      { id: 'CN01', name: 'Nómina' }
    ]
    
    invoiceForm: FormGroup = new FormGroup({});
    billForm: FormGroup = new FormGroup({});
  
    bill: any;
    transaction: any;
    unitData: any;
    invoiceData: any = {};
    utilityData: any = {};
  
    apiUrl: string = environment.AWS_REST_WSPREFIX;

  constructor(
      private toastr: ToastrService,
      private fb: FormBuilder,
      private store: SettlementsService,
      private activatedRoute: ActivatedRoute,
      private image: ImageService,
      public icons: IconsService
    ) { }

    
  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile')!).profile;
    this.activatedRoute.params.subscribe(params => {
      // console.log('params => ', params['id']);
      this.store.getBill(params['id']).subscribe((bill: any) => {
        console.log('BILL ======> ', bill);
        this.bill = bill;
        this.billForm.patchValue(bill);

        this.store.getTransactionByBill(bill.id).subscribe((transaction: any) => {
          console.log('TRANSACTION => ', transaction);
          // this.transaction = transaction;
        
      
        // console.log('data => ', data);
        this.transaction = transaction;

        this.formapago = transaction.payment_method_type == 'CASH' ? '01' : '04';

        this.store.getUnitSettlement(bill.unit_id).subscribe(async (response: any) => {
          console.log('UNIT SETTLEMENT =====> ', response);
          this.settlement = response.settlement;
          this.building = response.building;
          this.unit = response.unit;
          this.invoiceForm.patchValue(response.fiscal);
        });

        // this.store.getBill(data.bill_id).subscribe((bill: any) => {
          console.log('bill => ', bill);
          

          this.additionalConcepts = this.bill.additional_services ? JSON.parse(this.bill.additional_services) : [];

          this.store.getProfile(this.profile.id).subscribe(async (data: any) => {
            this.utilityData = data;
            if (this.utilityData.logo) {
              let logo: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(this.utilityData.logo));
              this.utilityData.logo = logo.error ? '/assets/images/empty-image.jpg' : "data:image/jpeg;base64," + logo.split(',')[1];
            }
          });

          this.store.getPreviousBill(bill.id).subscribe(async (prevBill: any) => {
            console.log('PREV BILL => ', prevBill);
            if (prevBill != null && prevBill.medidor) {
              let prev_medidor: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(prevBill.medidor));
              this.prev_medidor = this.image.buildURL(prevBill.medidor);
              this.billForm.patchValue({ medidor_prev: "data:image/jpeg;base64," + prev_medidor.split(',')[1] });
            } else {
              this.prev_medidor = '/assets/images/empty-image.jpg';
            }
          });

        });
      });
    });


    this.initForm();
    this.buildForm();
  }

  initForm() {
    this.invoiceForm = this.fb.group({
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      regimen_fiscal: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      zip_code: ['', Validators.required],
    });
  }

  buildForm() {
    this.billForm = this.fb.group({
      name: ['', Validators.required],
      reference: ['', Validators.required],
      description: [''],
      status: [''],
      consumo_m3: [''],
      consumo_lt: [''],
      consumo_periodo: [''],
      consumo_total: [''],      
      taxes: [''],
      sub_total: [''],
      total: [''],
      days: [''],
      interests: [''],
      read_prev: [''],
      read_curr: [''],
      discount: [''],
      admin_price: [''],
      recargos: [''],
      periodo_inicio: [''],
      periodo_fin: [''],
      due_date: [''],
      paid_date: [''],
      subscription_id: [''],
    });
  }

  get bfc () {
    return this.billForm.controls;
  }

  get ufc() {
    return this.invoiceForm.controls;
  }

  submit() {
    console.log('submit');
    console.log(this.invoiceForm.value);
    this.loading = true;

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

    console.log("localISOTime => ", localISOTime);

    let encabezados: any = {
      CFDIsRelacionados: "",
      TipoRelacion: "04",
      Receptor:{
        RFC: this.invoiceForm.value.rfc,
        NombreRazonSocial: this.invoiceForm.value.razon_social,
        UsoCFDI: this.invoiceForm.value.uso_cfdi,
        RegimenFiscal: this.invoiceForm.value.regimen_fiscal,
        Direccion: {
          Calle: "",
          NumeroExterior: "",
          NumeroInterior: "",
          Colonia: "",
          Localidad: "",
          Municipio: "",
          Estado: "",
          Pais: "",
          CodigoPostal: this.invoiceForm.value.zip_code
        }
      },
      Fecha: localISOTime.slice(0,19),
      Serie: this.serie.toString(),
      Folio: this.bill.id.toString(),
      MetodoPago:"PUE",
      FormaPago: this.formapago,
      Moneda: "MXN",
      LugarExpedicion: this.invoiceForm.value.zip_code,
      SubTotal:  this.billForm.value.sub_total.toFixed(2),
      Total: this.billForm.value.total.toFixed(2),
    };

    this.complemento = {
      formaPago: this.formapago,
      serie: this.serie,
      folio: this.bill.id,
      usoCFDI: this.invoiceForm.value.uso_cfdi,
      metodoPago: "PUE",
    }

    let payload: any = {
      bill: this.billForm.value,
      profile_id: this.profile.id,
      Encabezado: encabezados,
      Conceptos: this.billForm.value, // this.conceptos,
      complemento: this.complemento,
      utilityData: this.utilityData,
    };

    if (this.invoiceForm.invalid) {
      this.toastr.error('Completa toda la información requerida del formulario.', 'Error');
      this.loading = false;
      this.invoiceForm.markAllAsTouched();
      return;
    }

    console.log('PAYLOAD => ', payload);

    this.store.createInvoice(payload).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;
        this.invoiceData = data;
        this.download();
        this.toastr.success('Factura creada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al crear la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }

  download() {
    let dueDate = new Date(this.billForm.value.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);
    //
    this.store.downloadInvoice({
      bill: this.billForm.value,
      dueDate: dueDate,
      unitData: this.unit,
      buildingData: this.building,
      utilityData: this.utilityData,
      invoiceData: this.invoiceData
    }).subscribe({
      next: async (data: any) => {
        console.log('downloadInvoice => ', data.result.data);
        await this.store.saveInvoicePdf(this.invoiceData.id, { pdf: data.filename }).subscribe((response: any) => {
          

          const int8Array = new Uint8Array(data.result.data);
          const blob = new Blob([int8Array], { type: 'application/pdf' });

          var fileURL = window.URL.createObjectURL(blob);
          var link=document.createElement('a');

          link.href = fileURL;
          link.download = data.filename;
          link.click();

          const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

          var fileURL2 = window.URL.createObjectURL(blob2);
          var link2=document.createElement('a');

          link2.href = fileURL2;
          link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
          link2.click();

          window.location.reload();
          this.loading = false;
          
          return;
        });
      }, error: (error: any) => {
        this.toastr.error('Ocurrió un error al descargar la factura', 'Error');
        console.log('createReceipt ERROR => ', error);
        this.loading = false;
      }
      
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  cardType(card: any) {
    if (card == 'VI') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AMEX') {
      return "/assets/images/icon-amex-sm.png";
    } else if (card == 'CASH') {
      return "/assets/images/icon-cash.png";
    } else if (card == 'SALDO') {
      return "/assets/images/icon-cash.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }
}
