import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-utility-zone',
  template: `
    <ng-template #modalUtilityZone let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Zonas de servicio de gas</h5>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="zoneForm">
          <div class="form-group mt-3">
            <label for="zone_id" class="form-label">Zona</label>
            <select class="form-control" id="zone_id" formControlName="zone_id">
              <option value="">Selecciona una zona</option>
              <option *ngFor="let zone of zones" [value]="zone.id">{{zone.name}} - {{zone.description}}</option>
            </select>
            <div *ngIf="ff['zone_id'].touched && ff['zone_id'].invalid">
              <div *ngIf="ff['zone_id'].errors?.['required']" class="text-danger text-sm">El campo es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-3 mb-3 text-end">
            <button class="btn btn-primary" (click)="saveZone()">Agregar</button>
          </div>
        </form>
      </div>
    </ng-template>
  `,
  styles: [
  ]
})
export class ModalUtilityZoneComponent {
  @Input() utility: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalUtilityZone') modalUtilityZone !: ElementRef;

  private modalRef: NgbModalRef | undefined;
  zoneForm: FormGroup = new FormGroup({});
  zones: any = [];
  
  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    public icons: IconsService,
    private toastr: ToastrService,
    private modalService: NgbModal,

  ) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.loadZones();
  }

  initForm() {
    this.zoneForm = this.fb.group({
      zone_id: ['', Validators.required]
    });
  }

  get ff() { return this.zoneForm.controls; }

  loadZones() {
    this.store.getZones({ per_page: 1000, page: 1}).subscribe((res) => {
      const availableZones: any = res.data.filter((item: any) => !this.utility.zones.some((item2: any) => item.id === item2.id));
      this.zones = availableZones;
    });
  }

  saveZone() {
    if (this.zoneForm.valid) {
      console.log(this.zoneForm.value);
      this.store.addZoneToUtility({ utility_id: this.utility.id, zone_id: this.zoneForm.value.zone_id }).subscribe({});
      this.toastr.success('Zona de servicio de gas agregada correctamente');
      this.dismiss();
    } else {
      this.zoneForm.markAllAsTouched();
      this.toastr.error('Por favor complete los campos requeridos');
    }
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }
  
  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }

}
