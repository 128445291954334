import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tenant-payments',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-2 mt-sm-5">
        <div class="row">
          <div class="col-12 col-md-8">
            <h1>Historial de pagos</h1>
          </div>
          <div class="col-12 col-md-4 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 offset-sm-0 col-md-12">
            
            <div class="list-group rounded-5 shadow mt-2 mb-5" *ngIf="verifyBill">
              <div class="list-group-item bg-white" *ngFor="let bill of verifyBill | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: verifyMeta.total_entries}">
                <div class="row">
                  <div class="col-md-4 col-lg-4 col-xl-4">
                    <h4 class="mb-0">#{{bill.id}} <i>{{bill.reference}}</i></h4>
                    <p class="mb-0">{{bill?.settlement_name}} {{bill?.building_name}} {{bill?.unit_name}}</p>
                  </div>
                  <div class="col-4 text-end">
                    <p class="mb-0">Monto pagado</p>
                    <h3>{{bill.total | currency }}</h3>
                  </div>
                  <div class="col-4 text-end">
                    <div class="badge badge-pill rounded-5 bg-warning px-4 py-3"><h5 class="mb-0">En proceso de validación</h5></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-none d-sm-block">
              <div class="row mt-3">
                <div class="col-2">
                  <h5 class="ms-2"># Transacción</h5>
                </div>
                <div class="col-3">
                  <h5 class="ms-2">Unidad</h5>
                </div>
                <div class="col-2 text-center">
                  <h5>Consumo</h5>
                </div>
                <div class="col-2 text-center">
                  <h5>Pago</h5>
                </div>
                <div class="col-2 text-center">
                  <h5>Pagado</h5>
                </div>
              </div>
            </div>
            <div class="list-group rounded-5 shadow mt-2" *ngIf="bills.length>0">
              <div class="list-group-item" *ngFor="let bill of bills">
                <app-item-transaction [bill]="bill" [permissions]="permissions"></app-item-transaction>
              </div>
            </div>
            <div class="list-group rounded-5 shadow overflow-hidden text-center m-5">
              <div class="list-group-item p-0 m-5" *ngIf="bills.length==0&&loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="bills.length==0&&!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <p class="text-muted">No se encontraron registros</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4 mb-5" *ngIf="bills.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
            ></pagination-controls>
          </div>
        </div>
      </div>
  `,
  styleUrls: []
})
export class TenantPaymentsComponent implements OnInit {
  bills: any = [];
  verifyBill: any = [];
  verifyMeta: any = [];
  meta: any = [];
  tenant: any = {};
  permissions: any = {};
  loading: boolean = false;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  payload: any = {
    page: 1,
    per_page: 10,
    status: true,
    state: [1,2],
    // created_at_since: '',
    // created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  constructor (
    private store : SettlementsService,
    private activateRoute: ActivatedRoute,
    private route: Router,
    public icons: IconsService
  ) {
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;

    const adjustedDate = new Date(this.a.getFullYear(), this.a.getMonth(), this.a.getDate());
    const testDate = new Date(this.a.getFullYear(), 1, 15);
    const lastDay = new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0);

    this.fromDate = new NgbDate(adjustedDate.getFullYear()-1, adjustedDate.getMonth()+1, 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, lastDay.getDate());

    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }

  ngOnInit(): void {
    this.loading = true;
    this.activateRoute.params.subscribe((params: any) => {
      this.store.getTenant(params['id']).subscribe((data: any) => {
        this.tenant = data;
        console.log('TENANT => ', data);
        this.payload.unit_id = this.tenant.unit_id;
        this.loadBills();
      });
    });
  }

  loadBills() {
    this.store.getBillsByUnit(this.payload.unit_id, {
      page: 1,
      per_page: 100,
      status: false,
      state: 3,
      invoiced: false,
      created_at_since: this.payload.created_at_since,
      created_at_to: this.payload.created_at_to
    }).subscribe({
      next: (bills: any) => {
        this.verifyBill = bills.data;
        this.loading = false;
      }, error: (error: any) => {
        console.log('error => ', error);
        this.loading = false;
      }
    });
    this.store.getTransactionsByUnit(this.payload.unit_id, this.payload).subscribe((bills: any) => {
      console.log('bills => ', bills);
      this.bills = bills.data;
      this.loading = false;
    });
  }

  gotoPayment(bill: any) {
    this.route.navigate(['/tenant/bill', bill.id]);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadBills();
  }

  async downloadReceipt(bill: any) {
    console.log("downloadReceipt BILLL ===========")
    console.log(bill);

    // let unitData: any = {};
    // let buildingData: any = {};

    let dueDate = new Date(bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    console.log('dueDate => ', dueDate);

    this.store.getSubscriptions(bill.subscription_id).subscribe((data: any) => {
      console.log('getSubscription => ', data);      
      this.store.getUnit(data.unit_id).subscribe((unitData: any) => {
        // ! TODO: Refactor this is old code
        console.log('getTenantByUnit => ', data);
        this.store.getBuilding(1).subscribe(async (data: any) => {
          // console.log('getBuilding => ', data);
          if (bill.images && bill.images.length > 0) {
          let imageUrl = `${this.apiUrl}/storage/${bill.images[0].blob.key.substring(0,2)}/${bill.images[0].blob.key.substring(2,4)}/${bill.images[0].blob.key}`
          let imageUrl2 = `${this.apiUrl}/storage/${bill.images[1].blob.key.substring(0,2)}/${bill.images[1].blob.key.substring(2,4)}/${bill.images[1].blob.key}`

          await this.getBase64ImageFromUrl(imageUrl)
          .then(result => {
            let blobed = result;
            bill.image1 = blobed;
            console.log("BLOBED => ", blobed);
          })

          await this.getBase64ImageFromUrl(imageUrl2)
          .then(result => {
            let blobed = result;
            bill.image2 = blobed;
            // console.log("BLOBED => ", blobed);
          })
          .catch(err => console.error(err));
        }

          const payload = {
            bill: bill,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data
          };
  
          this.store.createReceipt(payload).subscribe({
            next: (data: any) => {
              console.log('createReceipt => ', data);
              // const payload = JSON.parse(data.result.data);
              // const buffer = payload.result.data;
              // console.log('buffer => ', buffer);
  
              // const byteString = window.atob(buffer);
              // const arrayBuffer = new ArrayBuffer(byteString.length);
              const int8Array = new Uint8Array(data.result.data);
              // for (let i = 0; i < byteString.length; i++) {
                // int8Array[i] = byteString.charCodeAt(i);
              // }
              const blob = new Blob([int8Array], { type: 'application/pdf' });
              console.log("blob ===> ", blob);
  
              var fileURL = window.URL.createObjectURL(blob);
              
              // window.download(data, '_blank');
  
              var link=document.createElement('a');
              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();
  
              return;
  
              // var file = new Blob([buffer.blob()], {type: 'application/pdf'});
              // var fileURL = window.URL.createObjectURL(file);
              // window.open(data, '_blank');
  
  
              // window.open(data, '_blank');
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
            }
          });

          // this.createReceipt(bill, dueDate, unitData, data);
        });
      });
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

}
