import { environment } from 'src/environments/environment';
import { PaymentsService } from './../../../../services/payments.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
declare var OpenPay: any;

@Component({
  selector: 'app-tenant-payment-method',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-2 mt-sm-5">
        <div class="row">
          <div class="col-12 col-md-5">
            <h1>Método de pago</h1>
            <p>Configura tus métodos de pago.</p>
          </div>
          <div class="col-12 col-md-7 mt-4 text-end">
          </div>
        </div>
      </div>
      <div class="col-10 offset-1 col-md-6 offset-sm-0 offset-md-3 mt-4">
        <div class="card rounded-5 mb-5 shadow">
          <div class="card-body p-3 p-sm-4">
            <form [formGroup]="payMethod" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-12 offset-md-0">
                  
                  <fieldset class="border-0 p-3 d-none">
                    <h5>Datos personales</h5>
                    
                    <div class="form-group mt-3">
                      <label for="email">Correo electrónico</label>
                      <input type="text" class="form-control" formControlName="email" id="email">
                      <div *ngIf="payM['email'].touched && payM['email'].invalid">
                        <div *ngIf="payM['email'].errors?.['required']" class="text-danger text-sm">El correo es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="phone">Teléfono</label>
                      <input type="text" class="form-control" formControlName="phone" id="phone">
                      <div *ngIf="payM['phone'].touched && payM['phone'].invalid">
                        <div *ngIf="payM['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="border-0 p-3 mt-2">
                    <h5>Datos de la tarjeta</h5>                    
                    <div class="form-group mt-5">
                      <label for="card_number">Número en la tarjeta</label>
                      <div class="input-group">
                        <input type="text" class="form-control form-control-lg border-end-0" formControlName="card_number" id="card_number" placeholder="XXXX-XXXX-XXXX-XXXX">
                        <span class="input-group-text input-group-text-lg bg-white border-start-0">
                          <div *ngIf="cardInput != ''">
                            <img [src]="cardInput" alt="Card image cap" width="28" class="float-left me-2">
                          </div>
                            <!-- img src="assets/images/icon-mastercard-sm.png" alt="Card image cap" width="54" class="float-left me-2">
                            <img src="assets/images/icon-amex-sm.png" alt="Card image cap" width="54" class="float-left me-2" -->
                        </span>
                      </div>
                      <div *ngIf="payM['card_number'].touched && payM['card_number'].invalid">
                        <div *ngIf="payM['card_number'].errors?.['required']" class="text-danger text-sm">El número de la tarjeta es requerido.</div>
                        <div *ngIf="!payM['card_number'].hasError('minLength')" class="text-danger text-sm">La longitud del número de la tarjeta no es válido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="name_on_card">Nombre que aparece en la tarjeta</label>
                      <input type="text" class="form-control text-uppercase form-control-lg rounded-3" formControlName="name_on_card" id="name_on_card">
                      <div *ngIf="payM['name_on_card'].touched && payM['name_on_card'].invalid">
                        <div *ngIf="payM['name_on_card'].errors?.['required']" class="text-danger text-sm">El nombre de la tarjeta es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="expiration_month">Mes</label>
                            <!-- input type="text" class="form-control" formControlName="expiration_month" id="expiration_month" -->
                            <select class="form-select" (change)="updateMonth($event)">
                              <option value="">-</option>
                              <option *ngFor="let month of catalogs.months" [value]="month.value">{{month.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="expiration_year">Año</label>
                            <!-- input type="text" class="form-control" formControlName="expiration_year" id="expiration_year" -->
                            <select class="form-select" (change)="updateYear($event)">
                              <option value="">-</option>
                              <option *ngFor="let year of catalogs.years" [value]="year.value">{{year.name}}</option>
                            </select>
                          </div>
                        </div>                    
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="cvv">CVV</label>
                            <input type="password" class="form-control" formControlName="cvv" id="cvv" maxlength="4">                            
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mt-3 text-end">
                            <p><small class="text-muted">MÉTODOS DE PAGO ACEPTADOS</small></p>
                            <img src="assets/images/icon-visa-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                            <img src="assets/images/icon-mastercard-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                            <img src="assets/images/icon-amex-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div *ngIf="payM['expiration_month'].touched && payM['expiration_month'].invalid">
                            <div *ngIf="payM['expiration_month'].errors?.['required']" class="text-danger text-sm">El mes es requerido.</div>
                          </div>
                          <div *ngIf="payM['expiration_year'].touched && payM['expiration_year'].invalid">
                            <div *ngIf="payM['expiration_year'].errors?.['required']" class="text-danger text-sm">El año es requerido.</div>
                          </div>
                          <div *ngIf="payM['cvv'].touched && payM['cvv'].invalid">
                            <div *ngIf="payM['cvv'].errors?.['required']" class="text-danger text-sm">El CVV es requerido.</div>
                            <div *ngIf="!payM['cvv'].hasError('minLength')" class="text-danger text-sm">La longitud del CVV no es válido.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="alias">Alias</label>
                      <input type="text" class="form-control form-control-lg rounded-3 text-uppercase" formControlName="alias" id="alias">
                      <div *ngIf="payM['alias'].touched && payM['alias'].invalid">
                        <div *ngIf="payM['alias'].errors?.['required']" class="text-danger text-sm">El nombre de la tarjeta es requerido.</div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="border-0 p-3 mt-4 mb-4">                
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Dirección</legend>
                    <div class="form-group mt-3">
                      <input class="form-check-input form-check-input-lg" type="checkbox" [checked]="selfAddress" id="flexCheckDefault" (change)="selectAddress($event)">
                      <label class="form-check-label ms-2" for="flexCheckDefault">
                        La dirección de la tarjeta es la misma que se tiene almacenada.
                      </label>
                    </div>
                    <div *ngIf="!selfAddress">
                      <div class="form-group mt-3">
                        <label for="address">Calle</label>
                        <input type="text" class="form-control form-control-lg rounded-3" formControlName="address" id="address" placeholder="Nombre">
                        <div *ngIf="payM['address'].touched && payM['address'].invalid">
                          <div *ngIf="payM['address'].errors?.['required']" class="text-danger text-sm">La calle es requerida.</div>
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <label for="state">Estado</label>
                        <!-- input type="text" class="form-control" formControlName="state" id="state" placeholder="Nombre" -->
                        <select class="form-select form-select-lg rounded-3" formControlName="state" (change)="updateState($event)">
                          <option value="">Selecciona un estado</option>
                          <option *ngFor="let state of catalogs.edos" [value]="state.value">{{state.name}}</option>
                        </select>
                        <div *ngIf="payM['state'].touched && payM['state'].invalid">
                          <div *ngIf="payM['state'].errors?.['required']" class="text-danger text-sm">El Estado es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <label for="city">Ciudad</label>
                        <input type="text" class="form-control form-control-lg rounded-3" formControlName="city" id="city">
                        <div *ngIf="payM['city'].touched && payM['city'].invalid">
                          <div *ngIf="payM['city'].errors?.['required']" class="text-danger text-sm">La ciudad es requerida.</div>
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <label for="zip_code">Código postal</label>
                        <input type="text" class="form-control form-control-lg rounded-3" formControlName="zip_code" id="zip_code">
                        <div *ngIf="payM['zip_code'].touched && payM['zip_code'].invalid">
                          <div *ngIf="payM['zip_code'].errors?.['required']" class="text-danger text-sm">El código postal es requerido.</div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <hr>
                  <div class="form-group mt-5 mb-3 me-2 text-end">
                    <button type="button" class="btn btn-link me-2" routerLink="/tenant/payment-methods/{{tenant.id}}">Cancelar</button>
                    <button type="submit" class="btn btn-outline-success btn-lg rounded-5 px-4 py-2">
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="text-success me-2 ms-2"></fa-icon>
                      <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="text-success me-2 ms-3 ms-sm-2"></fa-icon>
                      Agregar tarjeta
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantPaymentMethodComponent implements OnInit {
  payMethod: FormGroup = new FormGroup({});
  tenant: any = {};
  cardInput: string = '';
  loading: boolean = false;
  selfAddress: boolean = true;
  settlement: any = null;
  type_merchant: string = '';

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private store: SettlementsService,
    private toastr: ToastrService,
    private paymentsService: PaymentsService,
    public catalogs: CatalogsService,
    public icons: IconsService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.store.getTenant(params['id']).subscribe((data: any) => {

        this.store.getUnitSettlement(data.unit_id).subscribe((info: any) => {
          this.settlement = info.settlement;
        });

        this.store.getTenantAddress(data.id).subscribe((address: any) => {
          console.log(`tenant =======> `, data);
          console.log('address =======> ', address);
          this.tenant = data;
          this.payMethod.patchValue({
            email: this.tenant.email,
            phone: this.tenant.phone,
          });
          if (address) {
            this.payMethod.patchValue({
              // recurrent_customer_id: this.tenant.uid,
              country: address.country,
              state: address.state,
              city: address.city,
              address: address.address,
              zip_code: address.zip_code,
              card_type: 'visa',
            });
          } else {
            this.selfAddress = false;
          }
        });
      });
    });
  }

  ngOnInit(): void {
    this.buildForm();       
  }

  initializeOpenpay() {
    OpenPay.setSandboxMode(false);

    const merchant: string = `${this.settlement.profile_rs}_MERCHANT`;
    const pk: string = `${this.settlement.profile_rs}_PK`;
    const current_merchant = environment[merchant as keyof typeof environment];
    const current_pk = environment[pk as keyof typeof environment];

    OpenPay.setId(current_merchant);
    OpenPay.setApiKey(current_pk);
    var deviceDataId = OpenPay.deviceData.setup("formId");

    console.log('deviceDataId => ', deviceDataId);
    OpenPay.deviceData.setup("payment-form", "device_session_id");
  }

  buildForm() {
    this.payMethod = this.fb.group({
      //recurrent_customer_id: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      country: ['MX'],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      zip_code: ['', Validators.required],
      name_on_card: ['', Validators.required],
      card_number: ['', [Validators.required, Validators.minLength(15)]],
      expiration_year: ['', Validators.required],
      expiration_month: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      card_type: ['', Validators.required],
      alias: [''],
    });

    this.payMethod.get("card_number")!.valueChanges.subscribe(selectedValue  => {
      if (selectedValue.length > 3){
        let cct = this.creditCardType(selectedValue);
        this.cardInput = cct.icon;

        this.payMethod.patchValue({
          card_type: cct.card
        });
      }
    })
  }

  get payM() {
    return this.payMethod.controls;
  }

  updateState(event: any) {
    this.payMethod.patchValue({
      state: event.target.value
    });
  }

  updateYear(event: any) {
    this.payMethod.patchValue({
      expiration_year: event.target.value
    });
  }

  updateMonth(event: any) {
    this.payMethod.patchValue({
      expiration_month: event.target.value
    });
  }

  errorCallback(response: any) {
    console.error("Error creating token:", response);
  }

  async onSubmit() {
    this.loading = true;
    console.log('PAY METHOD FORM ===> ', this.payMethod.value);

    // if (this.settlement && this.settlement.merchant_id) {
      // this.type_merchant = this.settlement.merchant_id;
    // } else {
      this.type_merchant = 'actnet';
    // }

    if (this.payMethod.value.phone == null || this.payMethod.value.phone == '') {
      this.payMethod.patchValue({
        phone: '5555555555'
      });
    }

    

    let card_payload: any = {
      "type":"debit",
      "brand": this.payMethod.value.card_type,
      "address":{
         "line1": this.payMethod.value.address,
         "state": this.payMethod.value.state,
         "city": this.payMethod.value.city,
         "postal_code": this.payMethod.value.zip_code,
         "country_code":"MX"
      },
      "id":"kgipbqixvjg3gbzowl7l",
      "card_number": this.payMethod.value.card_number,
      "holder_name": this.payMethod.value.name_on_card,
      "expiration_year": this.payMethod.value.expiration_year.slice(-2),
      "expiration_month": this.payMethod.value.expiration_month,
      "allows_charges":true,
      "allows_payouts":false,
      // "creation_date":"20224-08-12T17:50:00-06:00",
      "bank_name":"DESCONOCIDO",
      "bank_code":"000",
      "customer_id": this.tenant.customer_id,
      "points_card":true,
      "cvv2": this.payMethod.value.cvv,
      "tenant_id": this.tenant.id,
      "alias": this.payMethod.value.alias,
      // "profile_id": this.tenant.profile_id,
   }

    if (this.payMethod.invalid) {      
      this.payMethod.markAllAsTouched();
      this.toastr.error(`Favor de llenar todos los campos.`, 'Error');
      this.loading = false;
      return;
    }

    if (this.type_merchant == 'openpay') {
      this.openpayCreateCard(card_payload);
    } else if (this.type_merchant == 'actnet') {
      this.actnetCreateCard(card_payload);
    }
  }

  protected async actnetCreateCard(card_payload: any) {
    const ctl = this;

    const payload: any = {
      "name_on_card": this.payMethod.value.name_on_card,
      "card_number": this.payMethod.value.card_number,
      "expiration_year": this.payMethod.value.expiration_year,
      "expiration_month": this.payMethod.value.expiration_month,
      "cvv": this.payMethod.value.cvv,
      "tenant_id": this.tenant.id,
    };

    console.log('payload => ', payload);

    ctl.paymentsService.tokenizeCard(payload).subscribe({
      next: (data: any) => {
        if (data.status == '400') {
          console.log("ERROR => ", data.message);
          let mesgs: string = '';
          mesgs += data.message.card_number ? 'El número de la tarjeta no es válido.' : '';
          mesgs += data.message.expiration_month ? 'La tarjeta está expirada.' : '';
          ctl.toastr.error(`Ocurrió un error al crear la tarjeta. ${mesgs}`, 'Error');
          ctl.loading = false;
          return;
        }

        ctl.toastr.success('Se ha creado el cliente', 'Exito');
        ctl.loading = false;
        ctl.route.navigate([`/tenant/payment-methods/${ctl.tenant.id}`]);
      },
      error: (err: any) => {
        console.log(err);
        ctl.toastr.error('Ocurrió un error al crear la tarjeta.', 'Error');
        ctl.loading = false;
      }
    });
  }

  protected async merchantOpenpayCustomer(tenant: any) {
    console.log('merchantOpenpayCustomer => ', tenant);
    let payload: any = {};
    payload = tenant;
    payload.profile_id = this.settlement.profile_id;
    return await this.paymentsService.createOpenpayCustomer(payload);
  }

  protected async openpayCreateCard(card_payload: any) {
    this.initializeOpenpay(); 
    const ctl = this;

    if (this.tenant && !this.tenant.customer_id) {
      let customer: any = await this.merchantOpenpayCustomer(this.tenant);
      card_payload.customer_id = customer.id;
    }

    console.log('card_payload => ', card_payload);
    OpenPay.token.create(card_payload, function (response: any) {
      console.log('OPENPAY successCallback response => ', response);
      console.log('this.tenant => ', ctl.tenant);

      let payload = response;
      payload.tenant_id = ctl.tenant.id;
      payload.card_type = ctl.creditCardType(card_payload.card_number).card;
      payload.deviceId = response.data.id;
      payload.card_number = card_payload.card_number;
      payload.cvv = card_payload.cvv2;
      payload.tenant_id = ctl.tenant.id;
      payload.customer_id = ctl.tenant.customer_id;
      payload.profile_id = ctl.settlement.profile_id;

      ctl.paymentsService.tokenizeCardOpenpay(payload).subscribe({
        next: (data: any) => {
          console.log('data => ', data);
          if (data.status == '400') {
            console.log("ERROR => ", data.message);
            let mesgs: string = '';
            mesgs += data.message.card_number ? 'El número de la tarjeta no es válido.' : '';
            mesgs += data.message.expiration_month ? 'La tarjeta está expirada.' : '';
            ctl.toastr.error(`Ocurrió un error al crear la tarjeta. ${mesgs}`, 'Error');
            ctl.loading = false;
            return
          } else {
            ctl.toastr.success('Se ha creado el cliente', 'Exito');
            ctl.loading = false;
            ctl.route.navigate([`/tenant/payment-methods/${ctl.tenant.id}`]);
          }
        },
        error: (err: any) => {
          console.log(err);
          ctl.toastr.error('Ocurrió un error al crear la tarjeta.', 'Error');
          ctl.loading = false;
        }
      });

    }, this.errorCallback);
  }

  selectAddress(event: any) {
    this.selfAddress = event.target.checked;

    /*if (this.selfAddress) {
      this.payMethod.patchValue({
        country: this.tenant.tenant_address ? this.tenant.tenant_address.country : 'MX',
        state: this.tenant.tenant_address.state,
        city: this.tenant.tenant_address.city,
        address: this.tenant.tenant_address.address,
        zip_code: this.tenant.tenant_address.zip_code
      });
    }*/
  }

  creditCardType(number: string) {
    const visa_regex = new RegExp('^4[0-9]{0,15}$');
    const mastercard_regex = new RegExp('^5$|^5[1-5][0-9]{0,14}$');
    const amex_regex = new RegExp('^3$|^3[47][0-9]{0,13}$');

    if (number.match(visa_regex))
      return { card: "VI", icon: "assets/images/icon-visa-sm.png" };

    if (number.match(mastercard_regex))
      return { card: "MC", icon: "assets/images/icon-mastercard-sm.png" };

    if (number.match(amex_regex))
      return { card: "AM", icon: "assets/images/icon-amex-sm.png" };

    return { card: "invalid", icon: "" };
  }

}
