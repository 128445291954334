import { AfterViewInit, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IconsService } from '../shared/services/icons.service';
import { SettlementsService } from '../services/settlements.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-add-contract',
  template: `
    <div #modalAddContract id="modalAddContract" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAddContract" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Agregar contrato</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-4">
                <h5>Empresa</h5>
                <p><strong>{{profile.name}}</strong></p>
                <p class="text-sm text-muted mb-0">RFC</p>
                <p>{{profile.rfc}}</p>
                <p class="text-sm text-muted mb-0">Dirección</p>
                <p>{{profile.address}}</p>

                <h5>Información legal</h5>
                <p class="text-sm text-muted mb-0">Representante legal</p>
                <p>{{public_record?.legal_name}} {{public_record?.legal_name_p}} {{public_record?.legal_name_m}}</p>
                <p>{{public_record?.ledger}}</p>
                <p>{{public_record?.property_full_address}}</p>

                <h5>Notario</h5>
                <p>{{public_record?.notary_full_name}}</p>
                <p>{{public_record?.notary_number}}</p>
                <p>{{public_record?.notary_full_address}}</p>
              </div>
              <div class="col-4">
                <h5 *ngIf="settlement?.tipo===0">Condominio</h5>
                <h5 *ngIf="settlement?.tipo===1">Comercio</h5>
                <p><strong>{{settlement?.name}}</strong></p>
                <p class="text-sm text-muted mb-0">Dirección</p>
                <p>{{settlement?.address}}, {{settlement?.city}},{{settlement?.state}} {{settlement?.zip}}, {{settlement?.country}}</p>
                <p class="text-sm text-muted mb-0">Costo administración</p>
                <p>{{settlement?.admin_price | currency: 'USD'}}</p>
                <p class="text-sm text-muted mb-0">Tarifa reconexión</p>
                <p>{{settlement?.reconexion_fee | currency: 'USD'}}</p>
                <p class="text-sm text-muted mb-0">Intereses</p>
                <p>{{settlement?.interests}} %</p>
                <h5>Datos Bancarios</h5>
                <p class="text-sm text-muted mb-0">Nombre</p>
                <p>
                  <span *ngIf="!settlement?.bank_name" class="text-danger">No hay cuenta</span>
                  <span *ngIf="settlement?.bank_name">{{settlement?.bank_name}}</span>
                </p>
                <p class="text-sm text-muted mb-0">Clabe</p>
                <p>
                  <span *ngIf="!settlement?.bank_clabe" class="text-danger">No hay cuenta</span>
                  <span *ngIf="settlement?.bank_clabe">{{settlement?.bank_clabe}}</span>                  
                </p>
              </div>
              <div class="col-4">
                <h5>Inquilino</h5>
                <div *ngIf="primary_tenant">
                  <p><strong>{{primary_tenant?.name}} {{primary_tenant?.last_name}} {{primary_tenant?.last_name_m}}</strong></p>
                  <p class="text-sm text-muted mb-0">RFC</p>
                  <p>{{primary_tenant?.public_record?.legal_rfc}}</p>
                  <p class="text-sm text-muted mb-0">Referencia banco</p>
                  <p *ngIf="primary_tenant?.bank_code">{{primary_tenant?.bank_code}}</p>
                  <p *ngIf="!primary_tenant?.bank_code" class="text-danger">No tiene cuenta asignada</p>

                  <h5>Información legal</h5>
                  <p class="text-sm text-muted mb-0">Representante legal</p>
                  <p>{{primary_tenant?.public_record?.legal_name}} {{primary_tenant?.public_record?.legal_last_name_p}} {{primary_tenant?.public_record?.legal_last_name_m}}</p>
                  <p>{{primary_tenant?.public_record?.ledger}}</p>

                  <h5>Notario</h5>
                  <p>{{primary_tenant?.public_record?.notary_full_name}}</p>
                  <p>{{primary_tenant?.public_record?.notary_number}}</p>
                </div>
                
                <h5>Tanque(s)</h5>
                <div *ngFor="let tanque of tanques">
                  <p><strong>{{tanque?.name}}</strong></p>
                  <p class="text-sm text-muted mb-0">Capacidad</p>
                  <p>{{tanque?.capacidad}} {{tanque?.unidad}}</p>
                </div>
                
                <h5>Zona</h5>
                <!-- table id="zone" class="table table-sm" *ngIf="zone !== undefined">
                  <tr><td>{{zone?.name}}</td></tr>
                </!-->
                
              </div>
              <!-- div class="col-12" *ngIf="contractTypes !== null">
                <app-form-contract [contractTypes]="contractTypes" (contractData)="createContract($event)"></app-form-contract>
              </!-->
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class ModalAddContractComponent implements OnInit {
  @Input() settlement: any;
  @Input() unit: any;
  @Input() tanque: any;
  @Input() tenants: any;
  @Input() contractTypes: any;
  @Input() profile: any;
  
  contract: any;
  public_record: any;
  tenant_public_record: any;
  zone: any = null;
  tanques: any = {};

  primary_tenant: any;

  // @ViewChild('modalInvoiceNota', { read: ElementRef, static: false }) modalAddContract !: ElementRef;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.store.getProfile(this.profile.id).subscribe((prof:any) => {
      this.store.getPublicRecord(prof.public_record_id).subscribe((data:any) => {
        console.log('load PUBLIC RECORD => ', data);
        this.public_record = data;
      });
    });

    console.log('TENANTS ???  => ', this.tenants);
    // console.log('modal settlement', this.settlement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settlement']&&changes['settlement'].currentValue) {
      if (changes['settlement'].currentValue.id) {
        this.loadZone(changes['settlement'].currentValue);
        this.loadTanques(changes['settlement'].currentValue);
      }
    }
    if (changes['tenants']&&changes['tenants'].currentValue) {
      console.log('changes TENANTS => ', changes['tenants'].currentValue);
      if(changes['tenants'].currentValue.length > 0) {
        this.getPrimaryTenant(changes['tenants'].currentValue);
      }

    }
  }

  loadZone(stl: any): void {
    this.store.getZone(stl.zone_id).subscribe((data:any) => {
      this.zone = data;
    });
  }

  loadTanques(stl: any): void {
    if (!stl) return;
    this.store.getTanquesBySettlement(stl?.id).subscribe({
      next: (data: any) => {      
        this.tanques = {
          total_tanques: data.length,
          total_lts: data.reduce((acc:any, t:any) => acc + t.capacidad, 0),
          tanques: data
        }
      }, error: (error: any) => {
        console.error(error);
      }
    });
    
  }

  getPrimaryTenant(tenants: any): any {
      this.primary_tenant = tenants.length === 1 ? tenants[0] : tenants.find((t:any) => t.is_primary === true);

    
    if (this.primary_tenant && this.primary_tenant) {
      this.store.getPublicRecord(this.primary_tenant.public_record_id).subscribe((data:any) => {
        console.log('primary tenant', this.tenant_public_record);
        this.primary_tenant.public_record = data;
      });
    }
  }

  createContract(data: any): void {
    console.log('createContract', data);
    console.log('contractTypes', this.contractTypes);
    console.log('contractType', this.contractTypes.find((c:any) => c.id === parseInt(data.contract_type_id)));
    console.log("tenants => ", this.tenants);
    console.log('tenant ====> ', this.tenants.find((t:any) => t.is_primary === true));
    console.log('public_record ====> ', this.public_record);

    if (!this.primary_tenant) {
      this.toastr.error('No hay inquilino asignado', 'Error');
      return;
    }

    const payload: any = {
      contract: this.contractTypes.find((c:any) => c.id === parseInt(data.contract_type_id)),
      tenant: this.primary_tenant, // .find((t:any) => t.is_primary === true),
      profile: this.profile,
      public_record: this.public_record,
      settlement: this.settlement,
      unit: this.unit,
      tanque: this.tanques,
      zone: this.zone,
    };

    console.log('payload', payload);
    

    if (this.tenants.length > 0 && !this.profile && !this.settlement && !this.unit && !this.tanque && !this.zone) {
      this.toastr.error('Datos incompletos', 'Error');
      console.log('Error: Datos incompletos');
      return;
    }

    // return;

    this.store.createContract(payload).subscribe({
      next:(data: any) => {
        console.log(data);
        this.toastr.success('Contrato creado', 'Éxito');
        window.location.reload();
        // TODO create contract with EasyLex
      },
      error:(error: any) => {
        console.log(error);
        this.toastr.error('No se pudo crear el contrato', 'Error');
      }
    });
  }
}
