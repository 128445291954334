import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faPlus, faCircleNotch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ModalUtilityZoneComponent } from 'src/app/components/modals/modal-utility-zone.component';

@Component({
  selector: 'app-service',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-3">        
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Servicio</h1>
            <p>Configura los servicios que están disponibles para los departamentos.</p>
            <xng-breadcrumb ></xng-breadcrumb>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow-sm">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar servicio</h4>
                    <p>Configura el servicio con los siguientes campos.</p>
                    <app-form-service (serviceData)="updateService($event)" [service]="service"></app-form-service>
                  </div>
                </div>        
              </div>
            </div>

            <div class="card bg-light border-0 rounded-4 shadow-sm mt-3">
              <div class="card-body p-4">
                <div class="d-flex mb-4">
                  <div class="flex-fill">
                    <h5 class="mt-1">Zonas de servicio</h5>
                  </div>
                  <div class="ms-auto me-3">
                    <button type="button" class="btn btn-sm btn-outline-primary shadow-sm" (click)="addZone()"><fa-icon [icon]="icons.faPlus"></fa-icon></button>                      
                  </div>
                </div>
                
                <div class="list-group">
                  <div class="list-group-item" *ngFor="let zone of service?.zones">
                    <div class="d-flex">
                      <div class="flex-fill">
                        <p class="mb-0">{{zone.name}} {{zone.description}}</p>
                      </div>
                      <div class="ms-auto text-end">
                        <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeZone(zone)"><fa-icon [icon]="icons.faTrashAlt"></fa-icon></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-10">
                    <h4 class="mt-1">Historial de precios ($ IVA incluido)</h4>
                  </div>
                  <div class="col-md-2 text-end">
                    <button type="button" class="btn btn-sm btn-outline-primary shadow-sm" (click)="addSettting()">
                      <fa-icon [icon]="icons.faPlus"></fa-icon>
                    </button>
                  </div>
                  <div class="col-md-12 mt-4" *ngIf="!addingSetting">
                    <div class="" *ngIf="settings.data != null">
                      <div class=" mb-3" *ngFor="let setting of groupedSettings | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: settings.meta?.total_entries }; ">
                        <div *ngIf="setting">
                          <div class="list-group">
                            <div class="list-group-item" *ngFor="let set of setting let i = index">
                              <app-item-price [setting]="set" [index]="i"></app-item-price>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="loadingSetting" class="text-center m-3">
                      <fa-icon [icon]="icons.faCircleNotch" size="1x" [spin]="true" class="text-secondary"></fa-icon>
                      <p class="text-muted">Cargando...</p>
                    </div>
                    <div class="list-group" *ngIf="settings.data?.length == 0">
                      <div class="list-group-item text-center py-5">
                        No se han registrado precios.
                      </div>
                    </div>
                    <!-- div class="text-end mt-4" *ngIf="settings.data?.length>0">
                      <pagination-controls
                        (pageChange)="p = $event"
                        (pageChange)="pageChanged($event)"
                        previousLabel="Prev."
                        nextLabel="Sig."
                        screenReaderPaginationLabel="Pagination"
                        screenReaderPageLabel="page"
                        *ngIf="settings.meta.total_pages > 1"
                      ></pagination-controls>
                    </!-->
                  </div>
                  <div class="col-md-12 mt-3" *ngIf="addingSetting">
                    <h4 class="mt-1">Agregar precio</h4>
                    <form [formGroup]="settingForm" (ngSubmit)="createUtilitySetting()">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="alert alert-warning">
                            <p class="mb-0">El precio se actualizará a partir de la fecha de hoy.</p>
                          </div>
                          <div class="form-group required mb-4 mt-3">
                            <label for="valor" class="form-label ms-2">Precio</label>
                            <input type="text" class="form-control form-control-lg rounded-3" id="valor" placeholder="" formControlName="valor">
                            <div *ngIf="ufc['valor'].touched && ufc['valor'].invalid">
                              <div *ngIf="ufc['valor'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                            </div>
                          </div>
                          <div class="form-group mb-4 mt-3">
                            <label for="name" class="form-label ms-2">Descripción</label>
                            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
                            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
                              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                            </div>
                          </div>
                          <div class="form-group mb-4 mt-3">
                            <label for="zone_id" class="form-label ms-2">Zona</label>
                            <select class="form-select form-select-lg rounded-3" id="zone_id" formControlName="zone_id">
                              <option *ngFor="let zona of zones" [value]="zona.id">{{zona.name}}</option>
                            </select>
                          </div>
                          <div class="form-group mb-0 mt-4 text-end">
                            <button type="button" class="btn btn-link" (click)="cancelAddSetting()">Cancelar</button>
                            <button type="submit" class="btn btn-success btn-lg rounded-5">Guardar</button>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
            </div>
          </div>
        </div>        
      </div>      
    </div>
    <app-modal-utility-zone [utility]="this.service" (updateDismiss)="refereshZones()"></app-modal-utility-zone>
  `,
  styleUrls: []
})
export class ServiceComponent implements OnInit {
  @ViewChild(ModalUtilityZoneComponent, { static: true}) modalUtilityZone !: ModalUtilityZoneComponent;
  service: any = null;
  settings: any = {};
  zones: any = [];
  loadingSetting: boolean = false;
  addingSetting: boolean = false;
  p: number = 1;

  groupedSettings: any = {};

  payload: any = {
    page: 1,
    per_page: 30
  }

  settingForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    public icons: IconsService,
  ) {
    
  }

  ngOnInit(): void {
    this.buildSettingForm();
    this.loadZones();
    this.initSetting();
  }

  buildSettingForm() {
    this.settingForm = this.fb.group({
      valor: [''],
      name: [''],
      utility_id: [''],
      zone_id: ['']
    });
  }

  loadZones() {
    this.store.getZones(this.payload).subscribe({
      next: (zones: any) => {
        this.zones = zones.data;
      },
      error: (err: any) => {
        this.zones = [];
      }
    });
  }

  initSetting() {
    this.loadingSetting = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getUtility(params['id']).subscribe({
        next: (res: any) => {
          console.log(res)
          this.service = res;

          this.settingForm.patchValue({
            utility_id: this.service.id
          });

          this.loadSettings();
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al obtener el servicio.', 'Error');
        }
      });
    });    
  }

  loadSettings() {
    this.store.getUtilitySettings(
      { utility_id: this.service.id, page: 1, per_page: 100 }).subscribe({
        next: (settings: any) => {
          console.log("loadSettings ", settings);

          const groupedByCategory = this.groupBy(settings.data, 'zone_id');
          this.groupedSettings = groupedByCategory;
          console.log("GROUPED ==> ", groupedByCategory);

          this.settings = settings;
          this.loadingSetting = false;
        },
        error: (err: any) => {
          this.settings.data = [];
          this.settings.meta = {};
          this.settings.meta.total_entries = 0;
          this.loadingSetting = false;
        }
      });
  }

  get ufc() {
    return this.settingForm.controls;
  }

  createUtilitySetting() {

    if(this.settingForm.invalid) {
      this.toastr.error('Ocurrió un error al crear el precio.', 'Error');
      return;
    }

    console.log("createUtilitySetting ", this.settingForm.value);    
    this.store.createUtilitySetting(this.settingForm.value).subscribe({
      next: (res: any) => {
        this.toastr.success('El precio se creó correctamente.', 'Exito');
        this.addingSetting = false;
        this.settingForm.reset();
        this.loadSettings();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al crear el precio.', 'Error');
      }
    });
  }

  updateService(event: any) {
    console.log("updateRole ", event);

    this.store.updateUtility(this.service.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('El servicio se actualizó correctamente.', 'Exito');
        this.router.navigate(['/services']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el servicio.', 'Error');
      }
    });
  }

  addSettting() {
    this.addingSetting = !this.addingSetting;
  }

  cancelAddSetting() {
    this.settingForm.reset();
    this.addingSetting = false;
  }

  pageChanged(event: any) {
    console.log("pageChanged ", event)
    this.payload.page = event;
    this.loadSettings();
  }

  groupBy(array: any, key: string) {
    return array.reduce((result: any, currentItem: any) => {
      // Get the value of the key for the current item
      const groupKey = currentItem[key];
      
      // If the groupKey does not exist in the result object, initialize it with an empty array
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      
      // Push the current item into the group
      result[groupKey].push(currentItem);
      
      // Return the updated result object
      return result;
    }, []); // Initial value for the result object
  };

  addZone() {
    console.log("addZone ");
    //this.store.createZone({ name: 'Nueva zona', description: 'Descripción de la zona' }).subscribe({
    const modalTemplate: any | undefined = this.modalUtilityZone;
    if (modalTemplate) {
      this.modalUtilityZone.openModal(modalTemplate['modalUtilityZone']);
    }
  }

  removeZone(zone: any) {}

  refereshZones() {
    this.loadZones();
  }

}
