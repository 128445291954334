import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-modal-settlement-statement',
  template: `
  <ng-template #modalSettlementStatement let-modal>
    <div class="modal-header"></div>
    <div class="modal-body p-4">
      <form [formGroup]="formStatement">
        <div class="form-group mt-4">
          <label for="created_at_since" class="form-label text-sm">Fecha inicial</label>
          <input type="date" class="form-control " formControlName="created_at_since" placeholder="Fecha inicial" bsDatepicker>
        </div>
        <div class="form-group mt-4">
          <label for="created_at_to" class="form-label text-sm">Fecha final</label>
          <input type="date" class="form-control" formControlName="created_at_to" placeholder="Fecha final" bsDatepicker>
        </div>
        <div class="form-group text-end mt-5">
          <button type="button" class="btn btn-primary" (click)="generateReport()">Generar reporte</button>
        </div>
      </form>
    </div>
  </ng-template>
  `,
  styleUrls: []
})
export class ModalSettlementStatementComponent {
  @Input() settlement : any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalSettlementStatement') modalSettlementStatement !: ElementRef;

  private modalRef: NgbModalRef | undefined;
  formStatement: FormGroup = new FormGroup({});

  constructor(
      private store: SettlementsService,
      private toastr: ToastrService,
      private fb: FormBuilder,
      private modalService: NgbModal,
      public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formStatement = this.fb.group({
      created_at_since: ['', Validators.required],
      created_at_to: ['', Validators.required]
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }

  generateReport() {
    this.store.getBillsBySettlement(this.settlement.settlement.id, {
      created_at_since: this.formStatement.value.created_at_since,
      created_at_to: this.formStatement.value.created_at_to,
      state: [1,2],
      page: 1,
      per_page: 10000
    }).subscribe((response: any) => {
      let bills: any = [];
      response.data.map((bill: any) => {
        bills.push({
          recibo: bill.id,
          condominio: bill.settlement_name,
          unidad: bill.unit_name,
          reference: bill.reference,
          lectura_prev: bill.read_prev,
          lectura_curr: bill.read_curr,
          pagado: bill.status,
          paid_date: bill.paid_date,
          consumo_m3: bill.consumo_m3,
          consumo_lt: bill.consumo_lt,
          consumo_periodo: bill.consumo_periodo,
          recargos: bill.recargos,
          adeudos: bill.interests,
          sub_total: bill.sub_total,
          taxes: bill.taxes,
          total: bill.total
        });
      });

      let options = {
        title: `Reporte de condominio ${this.settlement.settlement.name}`,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        noDownload: false,
        showTitle: true,
        useBom: true,
        headers: [
          '# Recibo',
          'Condomonio',
          'Unidad',
          'Referencia',
          'Lectura anterior',
          'Lectura actual',
          'Pagado',
          'Fecha pago',
          'Consumo M3',
          'Consumo Lts.',
          'Consumo en periodo',
          'Recargos',
          'Adeudos',
          'Subtotal',
          'IVA',
          'Total'
        ]
      };

      new ngxCsv(bills, 'Reporte Condominio', options);
    });
  }
}
