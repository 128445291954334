import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-generic',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">        
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Facturas</h1>
            <p>Consulta de facturas de ingreso.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="/invoices/create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar factura
            </!-->
            <button class="btn btn-lg btn-outline-pirmary text-primary border border-2 rounded-5 border-primary shadow" routerLink="/invoices/uploader">Generación masiva</button>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row">
          <div class="col-5">
            <app-filter-advanced
              [toDate]="toDate"
              [fromDate]="fromDate"
              (dateRange)="updateDate($event)"
            ></app-filter-advanced>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-primary" (click)="updateFilter()">Filtrar</button>
          </div>
          <div class="col-md-5">
            <form [formGroup]="searchBox" (ngSubmit)="searchInvoices($event)">
              <input class="form-control rounded-5 border-2 bg-transparent" placeholder="UUID..." formControlName="search">
            </form>
          </div>
        </div>        
        <!-- app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          (billsSettlement)="loadInvoicesBySettlement($event)"
          (billsBuilding)="loadInvoicesByBuilding($event)"
          (billsFloor)="loadInvoicesByFloor($event)"
          (billsUnit)="loadInvoicesByUnit($event)"
          (billsSort)="loadInvoicesSort($event)"
          (billsOrder)="loadInvoicesOrder($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></!-->
        <div class="row mb-4">
          <div class="col-md-12 mt-5 pb-3">
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length>0">
              <div class="list-group-item p-0 overflow-hidden" *ngFor="let invoice of invoices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-invoice-generic
                  [invoice]="invoice"
                ></app-item-invoice-generic>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="invoices.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceGenericComponent implements OnInit {
  invoices: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};

  loading: boolean = false;
  searchActive: boolean = false;

  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 100,
    tipo: 5,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate;
  toDate;

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile = this.profile.id;

    const adjustedDate = new Date(this.a.getFullYear(), this.a.getMonth(), this.a.getDate());
    const testDate = new Date(this.a.getFullYear(), 1, 15);
    const lastDay = new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0);

    this.fromDate = new NgbDate(adjustedDate.getFullYear(), adjustedDate.getMonth()+1, 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, lastDay.getDate());
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }

  ngOnInit(): void {
    this.loadInvoices(this.payload);
    this.initForm();
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadInvoices(this.payload);
    }
  }

  loadInvoices(payload: any) {
    this.loading = true;
    this.store.getInvoicesQueryGeneric(payload).subscribe({
      next: (invoices: any) => {
        this.invoices = invoices.data;
        this.meta = invoices.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.invoices = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las facturas.', 'Error');
        }
      }
    });
  }

  loadInvoicesUuid(payload: any) {
    this.loading = true;
    this.store.getInvoicesQueryGenericUuid(payload).subscribe({
      next: (invoices: any) => {
        this.invoices = invoices.data;
        this.meta = invoices.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.invoices = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las facturas.', 'Error');
        }
      }
    });
  }

  searchInvoices(event: any) {
    this.payload.page = 1;
    this.payload.uuid = this.searchBox.value.search;

    this.loadInvoicesUuid(this.payload);
  }

  updateFilter() {
    console.log('FILTER ==> ', this.fromDate, this.toDate);
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
    console.log('PAYLOAD ==> ', this.payload);
    this.loadInvoices(this.payload);
  }

  loadInvoicesBySettlement(settlement_id: any) {
    this.store.getInvoiceBySettlement(settlement_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByBuilding(building_id: any) {
    this.store.getInvoiceByBuilding(building_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByFloor(floor_id: any) {
    this.store.getInvoiceByFloor(floor_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByUnit(unit_id: any) {
    this.store.getInvoiceByUnit(unit_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesSort(event: any) {
    console.log('SORT EVENT ==> ', event);
    this.payload.sort = event;
    // this.payload.order = event.order;
    // this.loadBills(this.payload);
    this.getCurrentFilter();
  }

  loadInvoicesOrder(event: any) {
    console.log('ORDER EVENT ==> ', event);
    this.payload.order = event;
    this.getCurrentFilter();
  }

  getCurrentFilter() {
    console.log('CURRENT FILTER ==> ', this.payload);
    let count = 0;
    this.route.queryParamMap.subscribe(params => {      
      if (count>0){return;}
      console.log('PARAMS ==> ', params);

      if (params.has('unidad')) {
        this.payload.unit_id = params.get('unidad');
        this.loadInvoicesByUnit(this.payload.unit_id);
        return;
      }

      if (params.has('piso')) {
        this.payload.floor_id = params.get('piso');
        this.loadInvoicesByFloor(this.payload.floor_id);
        return;
      }

      if (params.has('edificio')) {
        this.payload.building_id = params.get('edificio');
        this.loadInvoicesByBuilding(this.payload.building_id);
        return;
      }

      if (params.has('condominio')) {
        this.payload.settlement_id = params.get('condominio');
        this.loadInvoicesBySettlement(this.payload.settlement_id);
        return;
      }
      count++;
    });
  }

  clearFilter() {
    this.loadInvoices(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadInvoices(this.payload);
  }
}
