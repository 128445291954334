import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentsService } from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
declare var OpenPay: any;

@Component({
  selector: 'app-tenant-bill',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
              <h1>Realizar pago</h1>
            <p>Ingresa la información para realizar el pagos.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar servicio</!-->
          </div>
          <div class="col-md-6">
            <div class="card border-0 rounded-5 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-6 col-md-8">
                    <h5 class="mb-1 text-secondary">Condominio</h5>
                    <h1 class="mb-0">{{unit?.name}}</h1>
                    <h4 class="text-secondary">{{settlement?.name}}, {{building?.name}}</h4>
                  </div>
                  <div class="col-6 col-md-4 text-end">
                    <p class="mb-1 text-muted text-secondary" style="font-size: 12px;">Fecha de vencimiento:</p>
                    <h5 class="badge badge-pill bg-info rounded-5 text-dark px-3 py-2"><b>{{bill.due_date | date: 'dd/MM/yy'}}</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-5 shadow mt-4 mb-4">
              <div class="card-body p-4">
              <fieldset class="mt-2 mb-0">
                <h5>Tarjetas</h5>
                <div class="form-group mt-3">
                  <div #cardList class="list-group rounded-4 shadow-sm bg-light" *ngIf="paymentMethods.length>0">
                    <div class="list-group-item list-group-item-action list-group-item-active py-2" *ngFor="let paymentMethod of paymentMethods" (click)="selectCard(paymentMethod)">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [value]="paymentMethod"  checked>
                        <div class="badge bg-light py-2 px-2 ms-2 border text-center float-start">
                          <img [src]="cardType(paymentMethod.card_type)" alt="Card image cap" width="44"> 
                        </div>
                        <div class="ms-3 float-start">
                        <p class="mb-0">{{paymentMethod.masked_card_number}}</p>
                        <p class="mb-0 text-sm text-muted"><small>{{cardName(paymentMethod.card_type)}} - {{paymentMethod.alias}}</small></p>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group rounded-4 shadow-sm bg-light" *ngIf="paymentMethods.length==0">
                    <div class="list-group-item text-center py-2" *ngIf="loadingMethods">
                      <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-info mt-2"></fa-icon>
                      <p class="text-muted">Cargando...</p>
                    </div>
                    <div class="list-group-item" *ngIf="!loadingMethods">
                      <p class="text-center mb-0 py-3">No hay métodos de pago registrados</p>
                    </div>
                  </div>
                  <h5 class="mt-5 mb-3">Pagar sin guardar tarjeta</h5>
                  <div class="list-group rounded-4 shadow-sm bg-light">
                    <div class="list-group-item">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="selectCard()" checked>
                        
                        <form [formGroup]="newCardForm">
                          <div class="form-group mt-2">
                            <label for="card_number" class="d-none">Número en la tarjeta</label>
                            <div class="input-group">
                              <input type="text" class="form-control form-control-lg border-end-0" formControlName="card_number" id="card_number" aria-describedby="emailHelp" placeholder="Número en la tarjeta">
                              <span class="input-group-text bg-white border-start-0">
                                <div *ngIf="cardInput != ''">
                                  <img [src]="cardInput" alt="Card image cap" width="28" class="float-left me-2">
                                </div>
                                  <!-- img src="assets/images/icon-mastercard-sm.png" alt="Card image cap" width="54" class="float-left me-2">
                                  <img src="assets/images/icon-amex-sm.png" alt="Card image cap" width="54" class="float-left me-2" -->
                              </span>
                            </div>
                            <div *ngIf="payM['card_number'].touched && payM['card_number'].invalid">
                              <div *ngIf="payM['card_number'].errors?.['required']" class="text-danger text-sm">El número de la tarjeta es requerido.</div>
                            </div>
                          </div>
                          <div class="form-group mt-3">
                            <label for="name_on_card">Nombre que aparece en la tarjeta</label>
                            <input type="text" class="form-control text-uppercase" formControlName="name_on_card" id="name_on_card" aria-describedby="emailHelp">
                            <div *ngIf="payM['name_on_card'].touched && payM['name_on_card'].invalid">
                              <div *ngIf="payM['name_on_card'].errors?.['required']" class="text-danger text-sm">El nombre de la tarjeta es requerido.</div>
                            </div>
                          </div>
                          <div class="form-group mt-3">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="expiration_month">Mes</label>
                                  <!-- input type="text" class="form-control" formControlName="expiration_month" id="expiration_month" -->
                                  <select class="form-select" (change)="updateMonth($event)">
                                    <option value="">-</option>
                                    <option *ngFor="let month of catalogs.months" [value]="month.value">{{month.name}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="expiration_year">Año</label>
                                  <!-- input type="text" class="form-control" formControlName="expiration_year" id="expiration_year" -->
                                  <select class="form-select" (change)="updateYear($event)">
                                    <option value="">-</option>
                                    <option *ngFor="let year of catalogs.years" [value]="year.value">{{year.name}}</option>
                                  </select>
                                </div>
                              </div>                    
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="cvv">CVV</label>
                                  <input type="password" class="form-control" formControlName="cvv" id="cvv" maxlength="4">
                                  
                                </div>
                              </div>
                              <div class="col-md-8 offset-md-4">
                                <div class="form-group mt-3 mb-2 text-end">
                                  <p class="mb-1"><small class="text-muted" style="font-size: 13px;">MÉTODOS DE PAGO ACEPTADOS</small></p>
                                  <img src="assets/images/icon-visa-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                                  <img src="assets/images/icon-mastercard-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                                  <img src="assets/images/icon-amex-sm.png" alt="Card image cap" width="44" class="float-left me-2">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div *ngIf="payM['expiration_month'].touched && payM['expiration_month'].invalid">
                                  <div *ngIf="payM['expiration_month'].errors?.['required']" class="text-danger text-sm">El mes es requerido.</div>
                                </div>
                                <div *ngIf="payM['expiration_year'].touched && payM['expiration_year'].invalid">
                                  <div *ngIf="payM['expiration_year'].errors?.['required']" class="text-danger text-sm">El año es requerido.</div>
                                </div>
                                <div *ngIf="payM['cvv'].touched && payM['cvv'].invalid">
                                  <div *ngIf="payM['cvv'].errors?.['required']" class="text-danger text-sm">El CVV es requerido.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <h5 class="mt-5 mb-3">Pago en efectivo</h5>
                  <div class="list-group rounded-4 shadow-sm bg-light">
                    <div class="list-group-item clearfix">
                      <p class="mt-2 float-start"><strong>Descargar recibo</strong></p>
                      <a href="{{receiptUrl}}/public/{{bill.pdf}}" target="_blank" class="btn btn-outline-info float-end rounded-4 mt-1" *ngIf="!bill.invoiced">Descargar</a>
                    </div>
                  </div>
                </div>
                  <div class="form-group mt-4 mb-1 text-end">
                    <button type="button" class="btn btn-lg btn-outline-success border border-2 border-success rounded-5 py-2 px-4" [disabled]="loading" (click)="pay(newCard)">
                      <fa-icon [icon]="icons.faCreditCard" class="me-2" *ngIf="!loading"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                      Realizar pago
                    </button>
                  </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 offset-0 offset-md-1">
          <app-receipt-reads [bfc]="billM" [medidor]="medidor" [prev_medidor]="prev_medidor"></app-receipt-reads>
          <app-receipt-breakdown
            [bfc]="billM"
            [additionalConcepts]="additionalServices"
            [interests]="interests"
          ></app-receipt-breakdown>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantBillComponent implements OnInit {
  @ViewChild('cardList') cardList: ElementRef | undefined;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;

  loading: boolean = false;
  editing: boolean = false;
  calculated: boolean = false;
  newCard: boolean = false;
  loadingMethods: boolean = false;

  bill: any = {};
  settlement: any = {};
  building: any = {};
  unit: any = {};
  selectedCard: any = {};
  tenant: any = {};

  medidor: string = '';
  prev_medidor: string = '';
  cardInput: string = '';
  
  billForm: FormGroup = new FormGroup({});
  cardPayForm: FormGroup = new FormGroup({});
  newCardForm: FormGroup = new FormGroup({});

  meterImages: any[] = [];
  paymentMethods: any = [];
  additionalServices: any[] = [];

  selectedMethod: number = 0;
  interests: number = 0;
  adeudos: number = 0;
  admon: number = 0;
  recargos: number = 0;

  token_cc: any;
  device_session_id: any;
  
  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private paymentsService: PaymentsService,
    private el: ElementRef,
    public catalogs: CatalogsService,
    public icons: IconsService,
    public image: ImageService
  ) {
    this.activateRoute.params.subscribe((params: any) => {
      this.loading = true;
      this.loadingMethods = true;

      let tLS = localStorage.getItem('user')
      let tL = JSON.parse(tLS || '{}');
      // TODO: Get token from environment
      

      this.store.getBill(params['id']).subscribe((data: any) => {
        // this.additionalServices = data.additional_services ? JSON.parse(data.additional_services) : [];
        this.bill = data;
        this.billForm.patchValue(this.bill);

        this.store.getUnitSettlement(data.unit_id).subscribe(async (response: any) => {
          // console.log('getUnitSettlement => ', response);
          this.settlement = response.settlement;
          this.building = response.building;
          this.unit = response.unit;
          this.tenant = response.tenants;
          // console.log('response => ', response.settlement.profile_rs);

          if (this.bill.merchant_id === 'actnet') {
            let token: any = `${response.settlement.profile_rs}_TOKEN_CC`;
            this.token_cc = environment[token as keyof typeof environment];
          } else if (this.bill.merchant_id === 'openpay') {
            let merchant_id: string = environment[`${response.settlement.profile_rs}_MERCHANT` as keyof typeof environment].toString();
            let merchant_pk: string = environment[`${response.settlement.profile_rs}_PK` as keyof typeof environment].toString();
            //
            this.initializeOpenpay(merchant_id, merchant_pk);
          }

          this.store.getPreviousBill(data.id).subscribe(async (prevBill: any) => {
            if (prevBill != null && prevBill.medidor) {
              let prev_medidor: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(prevBill.medidor));
              this.prev_medidor = this.image.buildURL(prevBill.medidor);
              this.billForm.patchValue({ medidor_prev: "data:image/jpeg;base64," + prev_medidor.split(',')[1] });
            } else {
              this.prev_medidor = '/assets/images/empty-image.jpg';
            }
          });

          if (data.medidor) {
            let medidor: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(data.medidor));
            this.medidor = this.image.buildURL(data.medidor);
            this.billForm.patchValue({ medidor: "data:image/jpeg;base64," + medidor.split(',')[1] });
          }
          /*
          await this.store.getLastTransactionByUnit(this.bill.id).subscribe((data: any) => {
            console.log('getLastTransactionByUnit => ', data);
            this.transaction = data;
          });*/
        });


        this.loading = false;
      });

      this.store.getTenant(tL.id).subscribe((data: any) => {
        this.tenant = data;
        console.log('tenant => ', data);

        this.cardPayForm.patchValue({
          customer_username: this.tenant.usename,
          first_name: this.tenant.name,
          last_name: this.tenant.last_name,
          birth_date: this.tenant.dob,
          email: this.tenant.email,
          phone: this.tenant.phone,            
          transaction_id: this.tenant.transaction_id,
          amount: this.tenant.amount,
          description: this.tenant.description,
          browser_user_agent: this.tenant.browser_user_agent,
        });

        this.newCardForm.patchValue({
          customer_username: this.tenant.usename,
          first_name: this.tenant.name,
          last_name: this.tenant.last_name,
          birth_date: this.tenant.dob,
          email: this.tenant.email,
          phone: this.tenant.phone,
          currency_code: "MXN",
          vip_level: 0
        });

        this.store.getTenantAddress(this.tenant.id).subscribe((address: any) => {
          console.log('address ===> ', address)
          this.cardPayForm.patchValue({
            country: address ? address.country : 'MX',
            state: address ? address.state : 'CMX',
            city: address ? address.city : 'CDMX',
            address: address ? address.address : 'Av Dr Gustavo Baz Sur 43',
            zip_code: address ? address.zip_code : '06000',
          });

          this.newCardForm.patchValue({
            country: address ? address.country : 'MX',
            state: address ? address.state : 'CMX',
            city: address ? address.city : 'CDMX',
            address: address ? address.address : 'Av Dr Gustavo Baz Sur 43',
            zip_code: address ? address.zip_code : '06000',
          });
        });
      });

      this.store.getPaymentMethodsByTenant(tL.id).subscribe({
        next: (data: any) => {
          this.paymentMethods = data;
          this.loadingMethods = false;
          // console.log('data ===> ', data);
          // console.log("getPaymentMethodsByTenant WHAT ====> ", this.el.nativeElement.get);
          if (data.length == 0) {
            this.selectCard();
          } else {
            this.selectedCard = data[0];
            this.selectCard(data[0]);
          }
        },
        error: (error: any) => {
          this.paymentMethods = [];
          this.loadingMethods = false;
        }
      });
      
    });
  }

  ngOnInit(): void {
    this.buildForm();  
    this.buildCard();
    this.buildNewCard();
  }

  initializeOpenpay(merchant: string, pk: string) {
    OpenPay.setSandboxMode(false);
    OpenPay.setId(merchant);
    OpenPay.setApiKey(pk);
    var deviceDataId = OpenPay.deviceData.setup("formId");
    this.device_session_id = deviceDataId;
  }


  buildForm() {
    this.billForm = this.fb.group({
      name: ['', Validators.required],
      reference: ['', Validators.required],
      description: [''],
      status: [''],
      consumo_m3: [''],
      consumo_lt: [''],
      consumo_periodo: [''],
      consumo_total: [''],
      taxes: [''],
      sub_total: [''],
      total: [''],
      days: [''],
      read_prev: [''],
      read_curr: [''],
      discount: [''],
      periodo_inicio: [''],
      periodo_fin: [''],
      due_date: [''],
      paid_date: [''],
      admin_price: [''],
      interests: [''],
      recargos: [''],    
      additional_services: [''],
      pdf: [''],  
      subscription_id: [''],
    });
  }

  buildCard() {
    this.cardPayForm = this.fb.group({
      customer_username: [''],
      first_name: [''],
      last_name: [''],
      birth_date: [''],
      email: [''],
      phone: [''],
      country: [''],
      state: [''],
      city: [''],
      address: [''],
      zip_code: [''],
      process_token: [''],
      token: ['', Validators.required],
      transaction_id: [''],
      amount: [''],
      currency_code: [''],
      description: [''],
      ip_address: [''],      
      browser_user_agent: [''],
    });
  }

  buildNewCard() {
    this.newCardForm = this.fb.group(
    {
      transaction_id:[''],
      amount:[''],
      currency_code:[''],
      description:[''],
      process_token:[''],
      customer_username:[''],
      first_name:[''],
      last_name:[''],
      birth_date:[''],
      email:[''],
      phone:[''],
      country:[''],
      state:[''],
      city:[''],
      address:[''],
      zip_code:[''],
      ip_address:[''],
      name_on_card:['', Validators.required],
      card_number:['', Validators.required],
      expiration_year:['', Validators.required],
      expiration_month:['', Validators.required],
      cvv:['', Validators.required],
      vip_level:[''],
    });
  }

  errorCallback(response: any) {
    console.error("Error creating token:", response);
    this.toastr.error('Error al intentar procesar la tarjeta.', 'Error');
    this.loading = false;
  }

  pay(card: any) {
    this.loading = true;
    let rtry: string = this.bill.retry > 0 ? `-${this.bill.retry}` : '';

    let payload: any = {
      customer_username: this.cardPayForm.value.customer_username,
      first_name: this.cardPayForm.value.first_name,
      last_name: this.cardPayForm.value.last_name,
      birth_date: this.cardPayForm.value.birth_date,
      email: this.cardPayForm.value.email,
      phone: this.cardPayForm.value.phone,
      country: 'MX', // this.cardPayForm.value.country,
      state: 'CMX', // this.cardPayForm.value.state,
      city: this.cardPayForm.value.city,
      address: this.cardPayForm.value.address,
      zip_code: this.cardPayForm.value.zip_code,
      process_token: this.token_cc,
      transaction_id: `${this.bill.id}-CC${rtry}`,
      ip_address: "201.207.239.230",
      amount: this.bill.total.toFixed(2),
      currency_code: "MXN",
      description: `OrdenCompra# ${this.bill.id}`,
      browser_user_agent: window.navigator.userAgent,
      profile_id: this.settlement.profile_id,
      device_session_id: this.device_session_id,
    };

    console.log('PAYMENT METHOD payload => ', this.bill.merchant_id);

    if (this.bill.merchant_id === 'actnet') { 
      this.processPaymentActnet(card, payload);
    } else if (this.bill.merchant_id === 'openpay') {
      this.processPaymentOpenpay(card, payload);
    }

    /*
    if (!card) {
      payload.token = this.cardPayForm.value.token;

      console.log('PAYMENT METHOD payload => ', payload);

      let card_payload = {
        holder_name: this.cardPayForm.value.first_name + ' ' + this.cardPayForm.value.last_name,
        card_number: '5555555555554444',
        expiration_year: '28',
        expiration_month: '12',
        cvv2: '110'
      }

    } else {
      if (this.newCardForm.invalid) {
        this.newCardForm.markAllAsTouched();
        this.toastr.error('Favor de llenar todos los campos.', 'Error');
        this.loading = false;
        return;
      }

      payload.name_on_card = this.newCardForm.value.name_on_card;
      payload.card_number = this.newCardForm.value.card_number;
      payload.expiration_year = this.newCardForm.value.expiration_year;
      payload.expiration_month = this.newCardForm.value.expiration_month;
      payload.cvv = this.newCardForm.value.cvv;
      payload.vip_level = 0;

      const ctl = this;

      let card_payload = {
        holder_name: this.newCardForm.value.name_on_card,
        card_number: this.newCardForm.value.card_number,
        expiration_year: this.newCardForm.value.expiration_year.slice(-2),
        expiration_month: this.newCardForm.value.expiration_month,
        cvv2: this.newCardForm.value.cvv
      }

    }
      */
  }

  protected async processPaymentActnet(card: any, payload: any) {

    console.log('ACTNET INITIALIZE PAYMENT ==> ', card);
    console.log("payload => ", payload);
    
    if (!card) {
      payload.token = this.cardPayForm.value.token;

      console.log('PAYMENT METHOD payload => ', payload);

      this.paymentsService.tokenizePayment(payload).subscribe((data: any) => {
        console.log('data => ', data);
        if (data.redirect_url) {        
          window.location.href = data.redirect_url;
        } else {
          this.handleError(data);
        }
        this.loading = false;
      });
    } else {

      if (this.newCardForm.invalid) {
        this.newCardForm.markAllAsTouched();
        this.toastr.error('Favor de llenar todos los campos.', 'Error');
        this.loading = false;
        return;
      }

      payload.name_on_card = this.newCardForm.value.name_on_card;
      payload.card_number = this.newCardForm.value.card_number;
      payload.expiration_year = this.newCardForm.value.expiration_year;
      payload.expiration_month = this.newCardForm.value.expiration_month;
      payload.cvv = this.newCardForm.value.cvv;
      payload.vip_level = 0;

      this.paymentsService.createPayment(payload).subscribe((data: any) => {
        console.log('data => ', data);
        if (data.redirect_url) {        
          window.location.href = data.redirect_url;
        } else {
          this.handleError(data);
        }
        this.loading = false;
      });
    }
  }

  protected async processPaymentOpenpay(card: any, payload: any) {

    console.log('OPENPAY INITIALIZE PAYMENT ==> ', payload);
    // return;

    if (!card) {
      const ctl = this;

       OpenPay.token.create(payload, function (response: any) {
        console.log('successCallback response => ', response);
        // console.log('this.tenant => ', this.tenant);
    
        let datos = {
          profile_id: ctl.settlement.profile_id,
          bill_id: ctl.bill.id,
          source_id: response.data.id,
          card: response.data.card,
          amount: payload.amount,
          description: payload.description,
          order_id: payload.transaction_id,
          device_session_id: payload.device_session_id,
          customer: {
            name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            phone_number: payload.phone,
          }
        };
        

      }, this.errorCallback);
    } else {

      const ctl = this;
      let card: any = {};

      if (this.newCardForm.invalid) {
        this.newCardForm.markAllAsTouched();
        this.toastr.error('Favor de llenar todos los campos.', 'Error');
        this.loading = false;
        return;
      }

      card.holder_name = this.newCardForm.value.name_on_card;
      card.card_number = this.newCardForm.value.card_number;
      card.expiration_year = this.newCardForm.value.expiration_year.slice(-2);
      card.expiration_month = this.newCardForm.value.expiration_month.slice(-2);
      card.cvv2 = this.newCardForm.value.cvv;
      card.address = {
        city: payload.city,
        line3: payload.city,
        postal_code: payload.zip_code,
        line1: payload.address,
        line2: '',
        state: payload.state,
        country_code: payload.country,
      };

      console.log('card => ', card);

      OpenPay.token.create(card, function (response: any) {
        console.log('successCallback response => ', response);
        // console.log('this.tenant => ', ctl.tenant);
 
        let datos = {
          profile_id: ctl.settlement.profile_id,
          bill_id: ctl.bill.id,
          source_id: response.data.id,
          card: response.data.card,
          amount: payload.amount,
          description: payload.description,
          order_id: payload.transaction_id,
          device_session_id: payload.device_session_id,
          customer: {
            name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            phone_number: payload.phone,
          }
        };
        ctl.paymentsService.createPaymentOpenpay(datos).subscribe({
          next: (data: any) => {
          console.log('data => ', data);
          if (data.payment_method) {        
            window.location.href = data.payment_method.url;
            ctl.loading = false;
          } else {
            ctl.handleError(data);
          }
          ctl.loading = false;
        },
        error: (error: any) => {
          ctl.toastr.error('Error al intentar procesar la tarjeta.', 'Error');
          ctl.loading = false;
        }
      });
    }, this.errorCallback);
    }
  }

  handleError(data: any) {
    if (!data['HTTPCode']) {
      if (data.error_code == "2") {
        this.store.createTransaction(data).subscribe((data: any) => {
          this.route.navigate(['/tenant/payment/success', this.bill.id]);
        });
      } else {
        this.store.createTransaction(data).subscribe({
          next: (data: any) => {
            this.route.navigate(['/tenant/payment/success', this.bill.id]);
          },
          error: (error: any) => {
            this.toastr.error(`Ocurrió un error al intentar procesar el pago. Operación rechazada por el banco, por favor intenta con otra método o intenta de nuevo más tarde.`, 'Error');
            this.loading = false;
          }
      });
      }
    } else {
      this.toastr.error(`Ocurrió un error al intentar procesar el pago. Operación rechazada por el banco, por favor intenta con otra método o intenta de nuevo más tarde.`, 'Error');
      this.loading = false;
    }
  }

  

  selectCard(event: any = null) {
    console.log('event XXXX=> ', event);
    if (event) {
      this.selectedCard = event;
      this.newCard = false;
      this.cardPayForm.patchValue({
        token: event.token,
      });
    } else {
      this.newCard = true;
    }
  }

  get payM() {
    return this.newCardForm.controls;
  }

  get billM() {
    return this.billForm.controls;
  }

  updateState(event: any) {
    this.cardPayForm.patchValue({
      state: event.target.value
    });
  }

  updateYear(event: any) {
    this.newCardForm.patchValue({
      expiration_year: event.target.value
    });
  }

  updateMonth(event: any) {
    this.newCardForm.patchValue({
      expiration_month: event.target.value
    });
  }

  cardType(card: any) {
    if (card == 'VI') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AM') {
      return "/assets/images/icon-amex-sm.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }

  cardName(card: any) {
    if (card == 'VI') {
      return "Visa";
    } else if (card == 'MC') {
      return "Mastercard"
    } else if (card == 'AM') {
      return "American Express";
    } else {
      return "";
    }
  }

}
