import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-global',
  template: `
    <div class="row">
    <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-9 offset-xl-3 mt-4">        
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Facturas globales</h1>
            <p>Administra las facturas globales.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
          <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.admin.invoices.global.write" routerLink="/invoices/globales/create">
            <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar factura
            </button>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-semi
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          (billsSettlement)="loadInvoicesBySettlement($event)"
          (billsBuilding)="loadInvoicesByBuilding($event)"
          (billsFloor)="loadInvoicesByFloor($event)"
          (billsUnit)="loadInvoicesByUnit($event)"
          (billsSort)="loadInvoicesSort($event)"
          (billsOrder)="loadInvoicesOrder($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-semi>
        <div class="row mb-4">
        
        <div class="row mb-4">
          <div class="col-md-12 mt-5 pb-3">
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length>0">
              <div class="list-group-item bg-light px-3 py-3" *ngFor="let invoice of invoices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-invoice-global 
                  [invoice]="invoice" 
                  [profile]="profile" 
                  [permissions]="permissions"
                  [utilityData]="utilityData"
                  [settlements]="settlements"
                ></app-item-invoice-global>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="invoices.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
        </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceGlobalComponent {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  loading: boolean = false;
  invoices: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});
  utilityData: any = {};
  settlements: any = [];

  payload: any = {
    page: 1,
    per_page: 100,
    tipo: 2,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile = this.profile.id;

    const adjustedDate = new Date(this.a.getFullYear(), this.a.getMonth(), this.a.getDate());
    const testDate = new Date(this.a.getFullYear(), 1, 15);
    const lastDay = new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0);

    this.fromDate = new NgbDate(adjustedDate.getFullYear(), adjustedDate.getMonth()+1, 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, lastDay.getDate());
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }
  

  ngOnInit(): void {
    this.loadSettlements();
    this.loadInvoices(this.payload);
    this.store.getProfile(this.profile.utility_id).subscribe((data: any) => {
      this.utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            this.utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', this.utilityData);
    });
    
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadInvoices(this.payload);
    }
  }

  loadSettlements(id: any = null) {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  loadInvoices(payload: any) {
    this.loading = true;
    this.store.getInvoicesQueryGlobal(payload).subscribe({
      next: (invoices: any) => {
        this.invoices = invoices.data;
        this.meta = invoices.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.invoices = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las facturas.', 'Error');
        }
      }
    });
  }

  loadInvoicesBySettlement(settlement_id: any) {
    this.payload.id = settlement_id;
    this.store.getGlobalInvoiceBySettlement(this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByBuilding(building_id: any) {
    this.store.getInvoiceByBuilding(building_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByFloor(floor_id: any) {
    this.store.getInvoiceByFloor(floor_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByUnit(unit_id: any) {
    this.store.getInvoiceByUnit(unit_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesSort(event: any) {
    console.log('SORT EVENT ==> ', event);
    this.payload.sort = event;
    // this.payload.order = event.order;
    // this.loadBills(this.payload);
    this.getCurrentFilter();
  }

  loadInvoicesOrder(event: any) {
    console.log('ORDER EVENT ==> ', event);
    this.payload.order = event;
    this.getCurrentFilter();
  }

  getCurrentFilter() {
    console.log('CURRENT FILTER ==> ', this.payload);
    let count = 0;
    this.route.queryParamMap.subscribe(params => {      
      if (count>0){return;}
      console.log('PARAMS ==> ', params);

      if (params.has('unidad')) {
        this.payload.unit_id = params.get('unidad');
        this.loadInvoicesByUnit(this.payload.unit_id);
        return;
      }

      if (params.has('piso')) {
        this.payload.floor_id = params.get('piso');
        this.loadInvoicesByFloor(this.payload.floor_id);
        return;
      }

      if (params.has('edificio')) {
        this.payload.building_id = params.get('edificio');
        this.loadInvoicesByBuilding(this.payload.building_id);
        return;
      }

      if (params.has('condominio')) {
        this.payload.settlement_id = params.get('condominio');
        this.loadInvoicesBySettlement(this.payload.settlement_id);
        return;
      }
      count++;
    });
  }

  clearFilter() {
    this.loadInvoices(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadInvoices(this.payload);
  }

  tag(status: any) {
    if (status === 'active') {
      return 'Activo';
    } else {
      return 'Inactivo';
    }
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
