import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-item-bill-paid',
  template: `
    <div class="row">
      <div class="col-12 col-sm-2 pt-2 text-center"> 
        <h3 class="mb-1 text-start float-start" style="line-height: 0.7;">
          <span class="mb-0"># {{bill.bill_id}} </span><br>
          <i class="text-sm text-center text-muted">{{bill.transaction_id}}</i>
        </h3>
        <div></div>
      </div>
      <div class="col-2 mt-0">
        <p class="mb-0" style="font-size: 17px;">{{bill?.unit_name}}</p>
        <p class="mb-0 text-sm" style="font-size: 13px;">{{bill?.settlement_name}}, {{bill?.building_name}}</p>
      </div>
      <div class="col-2 text-center mt-2">
        <p class="mb-0">
          <span class="text-danger" *ngIf="bill.consumo_lt < 0"><fa-icon [icon]="icons.faCircleExclamation"></fa-icon></span>
          {{bill.consumo_lt | number: '1.2-2'}} Lts.
        </p>
      </div>
      <div class="col-2 text-center mt-2">
        <p class="mb-0" style="font-size: 17px;">{{bill?.amount | currency: 'USD'}}</p>
      </div>
      <div class="col-6 col-md-2 text-center mt-2">
        <p class="mb-0">{{bill?.due_date | date: 'dd/MM/yy'}}</p>
      </div>
      <div class="col-6 col-md-1 text-center mt-2">
        <p class="mb-0">{{bill?.paid_date | date: 'dd/MM/yy'}}</p>
      </div>

      <div class="col-md-1 text-end mt-2">
        <div ngbDropdown class="d-inline-block ms-2">
          <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownBasic2" ngbDropdownToggle >
            <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a ngbDropdownItem href="{{receiptUrl}}/public/{{bill.pdf}}" target="_blank" [disabled]="!bill.pdf" *ngIf="!bill.invoiced">
              <fa-icon [icon]="icons.faDownload"></fa-icon>
              Descargar recibo
            </a>
            <!-- (click)="dispatchPayment()"  *ngIf="this.bill.date_status!='expirado'" -->
            <button ngbDropdownItem (click)="downloadPaymentReceipt(bill)">
              <fa-icon [icon]="icons.faDownload"></fa-icon>
              Descargar pago
            </button>
            <button ngbDropdownItem (click)="modifyDate(bill)" *ngIf="permissions?.modules.admin.billing.modify.write">Modificar fecha de pago</button>
            <button ngbDropdownItem class="btn btn-outline-primary ms-2" *ngIf="permissions?.modules.admin.billing.modify.write&&!bill?.invoiced&&!bill.adv" routerLink="/invoices/create/{{bill.bill_id}}">Facturar</button>
            <a ngbDropdownItem (click)="downloadInvoice(bill.invoice_id)" [disabled]="!bill.invoiced" *ngIf="bill.invoiced">
              <fa-icon [icon]="icons.faDownload"></fa-icon>
              Descargar factura
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemBillPaidComponent {
  @Input() bill: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Output() updateDate = new EventEmitter<any>();

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  invoicable: boolean = true;

  constructor(
    private store: SettlementsService,
    public icons: IconsService,
    public catalogs: CatalogsService,
    public images: ImageService
  ) { }

  async downloadPaymentReceipt(bill: any) {
    console.log('downloadPaymentReceipt => ', bill);


    console.log('profile => ', this.profile);

    await this.store.getTransactionByBill(bill.id).subscribe(async (transaction: any) => {

      const img = this.images.buildURL(this.profile.logo);
      const imx: any = await this.images.getBase64ImageFromUrl(img);
      this.profile.logo = `${imx.split(',')[1]}`;
    

      let payload = {
        bill: bill,
        transaction: transaction,
        profile: this.profile
      }

      console.log('payload => ', payload);

      this.store.downloadPaymentReceipt(payload).subscribe((data: any) => {
        const int8Array = new Uint8Array(data.result.data);
          const blob = new Blob([int8Array], { type: 'application/pdf' });

          var fileURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');

          link.href = fileURL;
          link.download = data.filename;
          link.click();
      });
    });
  }

  async downloadInvoice(id: any) {
    this.store.getInvoice(id).subscribe((data: any) => {
      console.log('invoice data => ', data);
      const int8Array = new Uint8Array(data.result.data);
      const blob = new Blob([int8Array], { type: 'application/pdf' });

      var fileURL = window.URL.createObjectURL(blob);

      var link = document.createElement('a');
      link.href = fileURL;
      link.download = data.filename;
      link.click();
    });
  }

  modifyDate(bill: any) {
    this.updateDate.emit(bill);
  }
}
