import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unpaid',
  template: `
    <div class="row">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-9 offset-xl-3 mt-5">
        <div class="row mb-2">
          <div class="col-md-8 col-lg-8">
            <h1>Recibos no emitidos</h1>
            <p>Consulta los recibios no emitidos.</p>
          </div>
          <div class="col-md-4 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->            
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          [payload]="payFilterPrefs"
          (billsSettlement)="loadBillsBySettlement($event)"
          (billsBuilding)="loadBillsByBuilding($event)"
          (billsFloor)="loadBillsByFloor($event)"
          (billsUnit)="loadBillsByUnit($event)"
          (billsSort)="loadBillsSort($event)"
          (billsOrder)="loadBillsOrder($event)"
          (billsPerPage)="loadBillsPerPage($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="row">
              <div class="col-2">
                <h5 class="ms-2"># Recibo</h5>
              </div>
              <div class="col-3">
                <h5 class="ms-2">Unidad</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Consumo</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Total a pagar</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Creado</h5>
              </div>
            </div>
            <div class="list-group rounded-4 shadow mt-2" *ngIf="bills.length > 0">
              <div class="list-group-item bg-white" *ngFor="let bill of bills | paginate: { itemsPerPage: payFilterPrefs.per_page, currentPage: p, totalItems: meta.total_entries}">
                <app-item-bill-pending [bill]="bill" [profile]="profile" [permissions]="permissions"></app-item-bill-pending>
              </div>
            </div>
            <div class="list-group rounded-4" *ngIf="bills.length==0">
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="180px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
              <div *ngIf="loading" class="text-center m-5 p-5">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-5" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              (pageBoundsCorrection)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="bills.length>0"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class UnpaidComponent implements OnInit {
  bills: any = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  
  searchBox: FormGroup = new FormGroup({});
  // searchActive: boolean = false;
  loading: boolean = false;

  payload: any = {    
    page: 1,
    per_page: 50,
    status: false,
    state: 0,
    // created_at_since: '',
    // created_at_to: '',
    // sort: 'created_at',
    // order: 'DESC'
  };

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  p: number = 1;
  units: any = [];

  payFilterPrefs: any = {};

  constructor(
    private store : SettlementsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;

    console.log('this.payload ==> ', this.payload);
    console.log('prefs ==> ', localStorage.getItem('payFilterPrefs'));
    console.log('this.payFilterPrefs ==> ', this.payFilterPrefs);

    const adjustedDate = new Date(this.a.getFullYear(), this.a.getMonth(), this.a.getDate());
    const testDate = new Date(this.a.getFullYear(), 1, 15);
    const lastDay = new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0);

    // this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.fromDate = new NgbDate(adjustedDate.getFullYear(), adjustedDate.getMonth()+1, 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, lastDay.getDate());

    console.log('this.fromDate ==> ', this.fromDate);
    console.log('this.toDate ==> ', this.toDate);
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;

    this.payFilterPrefs = localStorage.getItem('payFilterPrefs') != null ? JSON.parse(localStorage.getItem('payFilterPrefs')!) : this.payload;
    this.payFilterPrefs.state = 0;
    this.payFilterPrefs.profile_id = this.profile.id;
    this.payFilterPrefs.status = false;
    this.payFilterPrefs.state = 0;
    this.p = this.payFilterPrefs.page;

    this.loadBills(this.payFilterPrefs);
  }

  ngOnInit(): void {
    
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payFilterPrefs.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payFilterPrefs.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadBills(this.payFilterPrefs);
    }
  }

  loadBills(payload: any) {
    this.loading = true;
    this.bills = [];
    console.log('PAYLOAD ==> ', payload);
    this.store.getBillsQuery(payload).subscribe({
      next: (data: any) => {
        this.bills = data.data;
        this.meta = data.meta;
        this.loading = false;
        localStorage.setItem('payFilterPrefs', JSON.stringify(this.payFilterPrefs));
      }, error: (err: any) => {
        this.loading = false;
      }
    });
  }

  deleteBill(bill: any) {
    let confirmation = confirm('¿Está seguro de eliminar el recibo?');
    if (!confirmation) {
      return;
    } else {
      this.store.destroyBill(bill.id).subscribe({
        next: (data: any) => {
          this.toastr.success('Recibo eliminada correctamente', 'Éxito');
          this.loadBills(this.payload);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al eliminar el recibo', 'Error');
        }
      });
    }
  }

  loadBillsBySettlement(settlement_id: any) {
    this.payFilterPrefs.settlement_id = settlement_id;    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);   
  }

  loadBillsByBuilding(building_id: any) {
    this.payFilterPrefs.building_id = building_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByFloor(floor_id: any) {
    this.payFilterPrefs.floor_id = floor_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByUnit(unit_id: any) {
    this.payFilterPrefs.unit_id = unit_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsSort(event: any) {
    this.payFilterPrefs.sort = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsOrder(event: any) {
    this.payFilterPrefs.order = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsPerPage(event: any) {
    this.payFilterPrefs.per_page = parseInt(event);
    this.loadBills(this.payFilterPrefs);
  }

  clearFilter() {    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.settlement_id;
    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  pageChanged(event: any) {
    this.payFilterPrefs.page = event;
    this.loadBills(this.payFilterPrefs);
  }

}
