import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DashboardComponent } from './admin/pages/dashboard/dashboard.component';
import { SettlementComponent } from './admin/pages/settlement/settlement.component';
import { UnitComponent } from './components/unit/unit.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TenantsComponent } from './admin/pages/tenants/tenants.component';

import { ServicesComponent } from './admin/pages/services/services.component';
import { AmenitiesComponent } from './admin/pages/amenities/amenities.component';

import { ProvidersComponent } from './admin/pages/providers/providers.component';
import { PaymentsComponent } from './admin/pages/payments/payments.component';
import { ReceiptsComponent } from './admin/pages/payments/receipts/receipts.component';
import { ServiceComponent } from './admin/pages/services/service/service.component';
import { UtilitesComponent } from './admin/pages/utilites/utilites.component';
import { CargaRecibosComponent } from './components/carga-recibos/carga-recibos.component';
import { Papa } from 'ngx-papaparse';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { TenantComponent } from './admin/pages/tenants/tenant/tenant.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFireStorageModule } from '@angular/fire/compat/storage';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

import { AuthService } from './shared/services/auth.service';
import { UsersComponent } from './admin/pages/users/users.component';
import { RolesComponent } from './admin/pages/roles/roles.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { MaintenancesComponent } from './admin/pages/maintenances/maintenances.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BillComponent } from './admin/pages/bills/bill/bill.component';
import { AmenityComponent } from './admin/pages/amenities/amenity/amenity.component';
import { FormFloorComponent } from './components/form-floor/form-floor.component';
import { FormUnitComponent } from './components/form-unit/form-unit.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './admin/pages/users/profile/profile.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';

import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { TenantSignInComponent } from './components/tenant-sign-in/tenant-sign-in.component';
import { TenantSignUpComponent } from './components/tenant-sign-up/tenant-sign-up.component';
import { PaymentComponent } from './admin/pages/payments/payment/payment.component';
import { TenantProfileComponent } from './tenant/pages/tenant-profile/tenant-profile.component';
import { TenantBillsComponent } from './tenant/pages/tenant-bills/tenant-bills.component';
import { TenantBillComponent } from './tenant/pages/tenant-bills/tenant-bill/tenant-bill.component';
import { TenantPaymentsComponent } from './tenant/pages/tenant-payments/tenant-payments.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { TenantPaymentMethodsComponent } from './tenant/pages/tenant-payment-methods/tenant-payment-methods.component';
import { TenantPaymentMethodComponent } from './tenant/pages/tenant-payment-methods/tenant-payment-method/tenant-payment-method.component';
import { TenantDashboardComponent } from './tenant/pages/tenant-dashboard/tenant-dashboard.component';
import { TenantEditPaymentMethodComponent } from './tenant/pages/tenant-payment-methods/tenant-edit-payment-method/tenant-edit-payment-method.component';
import { TransactionSuccessComponent } from './tenant/pages/tenant-transactions/transaction-success/transaction-success.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { ShowBillComponent } from './admin/pages/bills/show-bill/show-bill.component';
import { BillTenantComponent } from './admin/pages/tenants/bill-tenant/bill-tenant.component';
import { IndexSettlementComponent } from './admin/pages/settlement/index-settlement/index-settlement.component';
import { TanquesComponent } from './admin/pages/tanques/tanques.component';
import { TanqueCreateComponent } from './admin/pages/tanques/tanque-create/tanque-create.component';
import { TanqueEditComponent } from './admin/pages/tanques/tanque-edit/tanque-edit.component';
import { FormTanqueComponent } from './components/form-tanque/form-tanque.component';
import { CreateSettlementComponent } from './admin/pages/settlement/create-settlement/create-settlement.component';
import { FilterPipe } from './filter.pipe';

import { FormsModule } from '@angular/forms';
import { BuildingFloorsComponent } from './components/building-floors/building-floors.component';
import { FormTenantComponent } from './components/form-tenant/form-tenant.component';
import { ProviderCreateComponent } from './admin/pages/providers/provider-create/provider-create.component';
import { ProviderEditComponent } from './admin/pages/providers/provider-edit/provider-edit.component';
import { FormProvidersComponent } from './components/form-providers/form-providers.component';
import { FormAmenityComponent } from './components/form-amenity/form-amenity.component';
import { AmenityCreateComponent } from './admin/pages/amenities/amenity-create/amenity-create.component';
import { ServiceCreateComponent } from './admin/pages/services/service-create/service-create.component';
import { FormServiceComponent } from './components/form-service/form-service.component';
import { FormUserComponent } from './components/form-user/form-user.component';
import { RoleCreateComponent } from './admin/pages/roles/role-create/role-create.component';
import { RoleEditComponent } from './admin/pages/roles/role-edit/role-edit.component';
import { FormRoleComponent } from './components/form-role/form-role.component';
import { ConfirmPasswordChangeComponent } from './components/confirm-password-change/confirm-password-change.component';
import { ItemTenantComponent } from './item-tenant/item-tenant.component';
import { ComercialSettlementsComponent } from './admin/pages/settlement/comercial-settlements/comercial-settlements.component';
import { UnpaidComponent } from './admin/pages/payments/unpaid/unpaid.component';
import { ItemServiceComponent } from './components/item-service/item-service.component';
import { ItemTinyTenantComponent } from './components/item-tiny-tenant/item-tiny-tenant.component';
import { ItemTinyUserComponent } from './components/item-tiny-user/item-tiny-user.component';
import { BillsSettlementComponent } from './admin/pages/settlement/bills-settlement/bills-settlement.component';
import { TanqueGaugeComponent } from './admin/pages/tanques/tanque-gauge/tanque-gauge.component';
import { ItemItemProviderComponent } from './components/item-item-provider/item-item-provider.component';
import { ItemTransactionComponent } from './components/item-transaction/item-transaction.component';
import { TechniciansComponent } from './admin/pages/technicians/technicians/technicians.component';
import { TechnicianEditComponent } from './admin/pages/technicians/technician-edit/technician-edit.component';
import { TechnicianCreateComponent } from './admin/pages/technicians/technician-create/technician-create.component';
import { FormTechnicianComponent } from './components/form-technician/form-technician.component';
import { ItemBillComponent } from './components/item-bill/item-bill.component';
import { RefuelsComponent } from './admin/pages/refuels/refuels.component';
import { OrdersComponent } from './admin/pages/orders/orders.component';
import { ModalOptionsPayComponent } from './components/modal-options-pay/modal-options-pay.component';
import { RefuelEditComponent } from './admin/pages/refuels/refuel-edit/refuel-edit.component';
import { RefuelCreateComponent } from './admin/pages/refuels/refuel-create/refuel-create.component';
import { OrderCreateComponent } from './admin/pages/orders/order-create/order-create.component';
import { OrderEditComponent } from './admin/pages/orders/order-edit/order-edit.component';
import { FormRefuelComponent } from './components/form-refuel/form-refuel.component';
import { FormOrderComponent } from './components/form-order/form-order.component';
import { InvoicesComponent } from './admin/pages/invoices/invoices.component';
import { InvoiceCreateComponent } from './admin/pages/invoices/invoice-create/invoice-create.component';

import { BreadcrumbModule } from "xng-breadcrumb";
import { BreadcrumbService } from 'xng-breadcrumb';
import { TenantInvoiceComponent } from './tenant/pages/tenant-invoice/tenant-invoice.component';
import { TenantInvoiceListComponent } from './tenant/pages/tenant-invoice-list/tenant-invoice-list.component';
import { ItemPaymentMethodComponent } from './components/item-payment-method/item-payment-method.component';
import { ItemTanqueComponent } from './components/item-tanque/item-tanque.component';
import { ItemOrderComponent } from './components/item-order/item-order.component';
import { ReceiptBreakdownComponent } from './components/receipt-breakdown/receipt-breakdown.component';
import { PaymentConceptsComponent } from './admin/pages/payment-concepts/payment-concepts.component';
import { ConceptEditComponent } from './admin/pages/payment-concepts/concept-edit/concept-edit.component';
import { ConceptCreateComponent } from './admin/pages/payment-concepts/concept-create/concept-create.component';
import { FormPaymentConceptComponent } from './components/form-payment-concept/form-payment-concept.component';
import { ItemConceptComponent } from './components/item-concept/item-concept.component';
import { ItemFormConceptComponent } from './components/item-form-concept/item-form-concept.component';
import { ReceiptReadsComponent } from './components/receipt-reads/receipt-reads.component';
import { ItemInvoiceComponent } from './components/item-invoice/item-invoice.component';
import { BillCommercialComponent } from './admin/pages/commercials/bill-commercial/bill-commercial.component';
import { TaskCreateComponent } from './admin/pages/maintenances/tasks/task-create/task-create.component';
import { TaskEditComponent } from './admin/pages/maintenances/tasks/task-edit/task-edit.component';
import { FormTaskComponent } from './components/form-task/form-task.component';
import { TaskIndexComponent } from './admin/pages/maintenances/tasks/task-index/task-index.component';
import { ModalAddBuildingComponent } from './components/modal-add-building/modal-add-building.component';
import { CategoryIndexComponent } from './admin/pages/maintenances/categories/category-index/category-index.component';
import { CategoryCreateComponent } from './admin/pages/maintenances/categories/category-create/category-create.component';
import { CategoryEditComponent } from './admin/pages/maintenances/categories/category-edit/category-edit.component';
import { FormTaskCategoryComponent } from './components/form-task-category/form-task-category.component';
import { BillsTenantsComponent } from './admin/pages/tenants/bills-tenants/bills-tenants.component';
import { BuildingUnitsComponent } from './components/building-units/building-units.component';
import { UnitDetailsComponent } from './components/unit-details/unit-details.component';
import { ItemTaskComponent } from './components/item-task/item-task.component';
import { ItemTinyTaskComponent } from './components/item-tiny-task/item-tiny-task.component';
import { CommercialsComponent } from './admin/pages/commercials/commercials.component';
import { CommercialComponent } from './admin/pages/commercials/commercial/commercial.component';
import { InvoiceShowComponent } from './admin/pages/invoices/invoice-show/invoice-show.component';
import { AmenityCalendarComponent } from './admin/pages/amenities/amenity-calendar/amenity-calendar.component';
import { AmenityTasksComponent } from './admin/pages/amenities/amenity-tasks/amenity-tasks.component';
import { AmenityTaskCreateComponent } from './admin/pages/amenities/amenity-task-create/amenity-task-create.component';
import { AmenityTaskEditComponent } from './admin/pages/amenities/amenity-task-edit/amenity-task-edit.component';
import { InvoiceComplementComponent } from './admin/pages/invoices/invoice-complement/invoice-complement.component';
import { InvoiceGlobalComponent } from './admin/pages/invoices/invoice-global/invoice-global.component';
import { ItemBillPendingComponent } from './components/item-bill-pending/item-bill-pending.component';
import { ModalInvoiceComplementComponent } from './components/modal-invoice-complement/modal-invoice-complement.component';
import { ItemSettlementComponent } from './components/item-settlement/item-settlement.component';
import { TenantInvoicesComponent } from './tenant/pages/tenant-invoices/tenant-invoices.component';
import { TenantInvoiceShowComponent } from './tenant/pages/tenant-invoices/tenant-invoice-show/tenant-invoice-show.component';
import { InvoiceCreateGlobalComponent } from './admin/pages/invoices/invoice-create-global/invoice-create-global.component';
import { ItemBillInvoiceComponent } from './components/item-bill-invoice/item-bill-invoice.component';
import { ProfileIndexComponent } from './admin/pages/profiles/profile-index/profile-index.component';
import { ProfileEditComponent } from './admin/pages/profiles/profile-edit/profile-edit.component';
import { ProfileCreateComponent } from './admin/pages/profiles/profile-create/profile-create.component';
import { ProfileShowComponent } from './admin/pages/profiles/profile-show/profile-show.component';
import { InvoiceDashboardComponent } from './admin/pages/invoices/invoice-dashboard/invoice-dashboard.component';
import { FilterAdvancedComponent } from './components/filter-advanced/filter-advanced.component';
import { InvoiceCreditnoteComponent } from './admin/pages/invoices/invoice-creditnote/invoice-creditnote.component';
import { InvoiceCreditnoteCreateComponent } from './admin/pages/invoices/invoice-creditnote-create/invoice-creditnote-create.component';
import { ItemProfileComponent } from './components/item-profile/item-profile.component';
import { FormProfileComponent } from './components/form-profile/form-profile.component';
import { ItemInvoiceGlobalComponent } from './components/item-invoice-global/item-invoice-global.component';
import { ItemInvoiceNotaComponent } from './components/item-invoice-nota/item-invoice-nota.component';
import { TenantUsersComponent } from './admin/pages/tenants/tenant-users/tenant-users.component';
import { TenantUserEditComponent } from './admin/pages/tenants/tenant-user-edit/tenant-user-edit.component';
import { FormConceptsComponent } from './components/form-concepts/form-concepts.component';
import { ModalInvoiceNotaComponent } from './components/modal-invoice-nota/modal-invoice-nota.component';
import { ItemCommercialComponent } from './components/item-commercial/item-commercial.component';
import { FormAbilityComponent } from './components/form-ability/form-ability.component';
import { FormReadingComponent } from './components/form-reading/form-reading.component';
import { CurrencyToTextPipe } from './pipes/currency-to-text.pipe';
import { IndexZoneComponent } from './admin/pages/zones/index-zone/index-zone.component';
import { EditZoneComponent } from './admin/pages/zones/edit-zone/edit-zone.component';
import { CreateZoneComponent } from './admin/pages/zones/create-zone/create-zone.component';
import { FormZoneComponent } from './components/form-zone/form-zone.component';
import { IndexReportsComponent } from './admin/pages/reports/index-reports/index-reports.component';
import { IndexBalancesComponent } from './admin/pages/payments/balances/index-balances/index-balances.component';
import { GenerateComponent } from './admin/pages/payments/generate/generate.component';
import { ToolIndexComponent } from './admin/pages/tasks/tools/tool-index/tool-index.component';
import { ToolEditComponent } from './admin/pages/tasks/tools/tool-edit/tool-edit.component';
import { ToolCreateComponent } from './admin/pages/tasks/tools/tool-create/tool-create.component';
import { FormToolComponent } from './components/form-tool/form-tool.component';
import { SkillIndexComponent } from './admin/pages/tasks/skills/skill-index/skill-index.component';
import { SkillEditComponent } from './admin/pages/tasks/skills/skill-edit/skill-edit.component';
import { SkillCreateComponent } from './admin/pages/tasks/skills/skill-create/skill-create.component';
import { VehiclesIndexComponent } from './admin/pages/tasks/vehicles/vehicles-index/vehicles-index.component';
import { VehiclesEditComponent } from './admin/pages/tasks/vehicles/vehicles-edit/vehicles-edit.component';
import { VehiclesCreateComponent } from './admin/pages/tasks/vehicles/vehicles-create/vehicles-create.component';
import { BuilderIndexComponent } from './admin/pages/tasks/builder/builder-index/builder-index.component';
import { BuilderEditComponent } from './admin/pages/tasks/builder/builder-edit/builder-edit.component';
import { BuilderCreateComponent } from './admin/pages/tasks/builder/builder-create/builder-create.component';
import { FormSkillComponent } from './components/form-skill/form-skill.component';
import { FormVehicleComponent } from './components/form-vehicle/form-vehicle.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { ReceiptTinyComponent } from './components/receipt-tiny/receipt-tiny.component';
import { ItemBalanceComponent } from './components/item-balance/item-balance.component';
import { MeterIndexComponent } from './admin/pages/gas-meters/meter-index/meter-index.component';
import { MeterEditComponent } from './admin/pages/gas-meters/meter-edit/meter-edit.component';
import { MeterCreateComponent } from './admin/pages/gas-meters/meter-create/meter-create.component';
import { FormGasMeterComponent } from './components/form-gas-meter/form-gas-meter.component';
import { ItemGasMeterComponent } from './components/item-gas-meter/item-gas-meter.component';
import { ItemTinyGasMeterComponent } from './components/item-tiny-gas-meter/item-tiny-gas-meter.component';
import { ItemSkillComponent } from './components/item-skill/item-skill.component';
import { ItemVehicleComponent } from './components/item-vehicle/item-vehicle.component';
import { ItemToolComponent } from './components/item-tool/item-tool.component';
import { ModalWorkScheduleComponent } from './components/modal-work-schedule/modal-work-schedule.component';
import { ModalVehiclesComponent } from './components/modal-vehicles/modal-vehicles.component';
import { ModalSkillsComponent } from './components/modal-skills/modal-skills.component';
import { ModalToolsComponent } from './components/modal-tools/modal-tools.component';
import { ActivityIndexComponent } from './admin/pages/tasks/activities/activity-index/activity-index.component';
import { ActivityEditComponent } from './admin/pages/tasks/activities/activity-edit/activity-edit.component';
import { ActivityCreateComponent } from './admin/pages/tasks/activities/activity-create/activity-create.component';
import { ActivityTypeIndexComponent } from './admin/pages/tasks/activity-types/activity-type-index/activity-type-index.component';
import { ActivityTypeEditComponent } from './admin/pages/tasks/activity-types/activity-type-edit/activity-type-edit.component';
import { ActivityTypeCreateComponent } from './admin/pages/tasks/activity-types/activity-type-create/activity-type-create.component';
import { FormActivityComponent } from './components/form-activity/form-activity.component';
import { FormActivityTypeComponent } from './components/form-activity-type/form-activity-type.component';
import { DndModule } from 'ngx-drag-drop';
import { CountUpDirective } from './count-up.directive';
import { IndexChartComponent } from './components/charts/index-chart/index-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DashboardSettlementComponent } from './admin/pages/settlement/dashboard-settlement/dashboard-settlement.component';
import { DashboardTechniciansComponent } from './admin/pages/technicians/dashboard-technicians/dashboard-technicians.component';
import { HolidaysComponent } from './admin/pages/tasks/holidays/holidays.component';
import { ModalHolidayComponent } from './components/modal-holiday/modal-holiday.component';
import { TaskReviewComponent } from './admin/pages/maintenances/tasks/task-review/task-review.component';
import { MapComponent } from './components/map/map.component';
import { ItemRefuelComponent } from './components/item-refuel/item-refuel.component';
import { ModalSetPriceComponent } from './components/modal-set-price/modal-set-price.component';
import { InvoiceCanceledComponent } from './admin/pages/invoices/invoice-canceled/invoice-canceled.component';
import { ItemInvoiceCanceledComponent } from './components/item-invoice-canceled/item-invoice-canceled.component';
import { ModalInvoiceCancelComponent } from './components/modal-invoice-cancel/modal-invoice-cancel.component';
import { InvoiceShowGlobalComponent } from './admin/pages/invoices/invoice-show-global/invoice-show-global.component';
import { UserCreateComponent } from './admin/pages/users/user-create/user-create.component';
import { ItemTaskTenantComponent } from './components/item-task-tenant/item-task-tenant.component';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { ItemPriceComponent } from './components/item-price/item-price.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { NotificationsComponent } from './admin/pages/notifications/notifications.component';
import { UnassignedComponent } from './admin/pages/maintenances/tasks/unassigned/unassigned.component';
import { ItemUnassignedComponent } from './components/item-unassigned/item-unassigned.component';
import { TaskRefuelComponent } from './admin/pages/maintenances/tasks/task-refuel/task-refuel.component';
import { ModalAsignTaskComponent } from './components/modal-asign-task/modal-asign-task.component';
import { ModalVehicleAsignComponent } from './components/modal-vehicle-asign/modal-vehicle-asign.component';
import { DashboardCommercialComponent } from './admin/pages/commercial/dashboard-commercial/dashboard-commercial.component';
import { GaugeChartComponent } from './components/charts/gauge-chart/gauge-chart.component';
import { initializeApp } from "firebase/app";
import { NotifyFloaterComponent } from './components/notify-floater/notify-floater.component';
import { ItemTaskRefuelComponent } from './components/item-task-refuel/item-task-refuel.component';
import { ModalAsignTaskOperatorComponent } from './components/modal-asign-task-operator/modal-asign-task-operator.component';
import { SearchTenantsComponent } from './components/search-tenants/search-tenants.component';
import { TasksDashboardComponent } from './admin/pages/maintenances/tasks/tasks-dashboard/tasks-dashboard.component';
import { FilterBasicComponent } from './components/filter-basic/filter-basic.component';
import { ModalInvoiceConfigComponent } from './components/modal-invoice-config/modal-invoice-config.component';
import { InvoiceUploaderComponent } from './admin/pages/invoices/invoice-uploader/invoice-uploader.component';
import { ItemSmTenantComponent } from './components/item-sm-tenant/item-sm-tenant.component';
import { ModalTenantComponent } from './components/modal-tenant/modal-tenant.component';
import { ModalGasMeterComponent } from './components/modal-gas-meter/modal-gas-meter.component';
import { FilterFullComponent } from './components/filter-full/filter-full.component';
import { InvoiceGenericComponent } from './admin/pages/invoices/invoice-generic/invoice-generic.component';
import { ModalBillVerifyComponent } from './components/modal-bill-verify/modal-bill-verify.component';
import { ItemInvoiceGenericComponent } from './components/item-invoice-generic/item-invoice-generic.component';
import { TransactionFailComponent } from './tenant/pages/tenant-transactions/transaction-fail/transaction-fail.component';
import { CreateCustomBillComponent } from './admin/pages/bills/create-custom-bill/create-custom-bill.component';
import { BarChartConsumptionsComponent } from './components/charts/bar-chart-consumptions/bar-chart-consumptions.component';
import { PieChartPortfolioComponent } from './components/charts/pie-chart-portfolio/pie-chart-portfolio.component';
import { LineChartConsumptionComponent } from './components/charts/line-chart-consumption/line-chart-consumption.component';
import { ProfileDashboardComponent } from './admin/pages/profiles/profile-dashboard/profile-dashboard.component';
import { TransactionsPaymentsComponent } from './admin/pages/payments/transactions-payments/transactions-payments.component';
import { ModalInventoryReportComponent } from './components/modal-inventory-report/modal-inventory-report.component';
import { ReportInvoicesIngresoComponent } from './components/reports/report-invoices-ingreso.component';
import { ReportInvoicesCanceledComponent } from './components/reports/report-invoices-canceled.component';
import { ReportInventoryComponent } from './components/reports/report-inventory.component';
import { ReportInvoicesGlobalComponent } from './components/reports/report-invoices-global.component';
import { ReportInvoicesNotasComponent } from './components/reports/report-invoices-notas.component';
import { ReportInvoicesGenericComponent } from './components/reports/report-invoices-generic.component';
import { ModalInvoicesGenericComponent } from './components/reports/modal-invoices-generic.component';
import { ModalInvoicesCanceledComponent } from './components/reports/modal-invoices-canceled.component';
import { ModalInvoicesGlobalComponent } from './components/reports/modal-invoices-global.component';
import { ModalInvoicesIngresoComponent } from './components/reports/modal-invoices-ingreso.component';
import { ModalInvoicesNotasComponent } from './components/reports/modal-invoices-notas.component';
import { RefuelShowComponent } from './admin/pages/refuels/refuel-show/refuel-show.component';
import { StlBarChartConsumptionsComponent } from './components/charts/stl-bar-chart-consumptions.component';
import { AnovaLineChartConsumptionsComponent } from './components/charts/anova-line-chart-consumptions.component';
import { ItemBillPaidComponent } from './components/item-bill-paid/item-bill-paid.component';
import { FilterSemiComponent } from './components/filter-semi/filter-semi.component';
import { ModalGasMeterMaintenanceComponent } from './components/modal-gas-meter-maintenance/modal-gas-meter-maintenance.component';
import { ContractUnitComponent } from './components/contract-unit.component';
import { ContractSettlementComponent } from './components/contract-settlement.component';
import { ModalAddContractComponent } from './components/modal-add-contract.component';
import { FormContractComponent } from './components/form-contract/form-contract.component';
import { ItemContractComponent } from './components/item-contract.component';
import { FormPublicRecordComponent } from './components/form-public-record/form-public-record.component';
import { SignInInvoiceComponent } from './components/sign-in-invoice/sign-in-invoice.component';
import { ItemTenantInvoiceComponent } from './components/item-tenant-invoice/item-tenant-invoice.component';
import { InvoiceAcceptanceComponent } from './admin/pages/invoices/invoice-acceptance/invoice-acceptance.component';
import { ModalSetBillDateComponent } from './components/modal-set-bill-date/modal-set-bill-date.component';
import { ContractIndexComponent } from './admin/pages/contracts/contract-index/contract-index.component';
import { ContractEditComponent } from './admin/pages/contracts/contract-edit/contract-edit.component';
import { ContractTypesIndexComponent } from './admin/pages/contracts/contract-types-index/contract-types-index.component';
import { ContractTypesEditComponent } from './admin/pages/contracts/contract-types-edit/contract-types-edit.component';
import { ItemContractTypesComponent } from './components/item-contract-types/item-contract-types.component';
import { FormContractTypesComponent } from './components/form-contract-types/form-contract-types.component';
import { MeterUnitComponent } from './components/meter-unit.component';
import { ModalGasMeterMaintenanceRemoveComponent } from './components/modal-gas-meter-maintenance-remove/modal-gas-meter-maintenance-remove.component';
import { FormTenantFiscalComponent } from './components/form-tenant-fiscal/form-tenant-fiscal.component';
import { FormTenantAddressComponent } from './components/form-tenant-address/form-tenant-address.component';
import { FormUserPasswordComponent } from './components/form-user-password/form-user-password.component';
import { ModalUserPasswordComponent } from './components/modal-user-password/modal-user-password.component';
import { ModalUserFormComponent } from './components/modal-user-form/modal-user-form.component';
import { TenantUnitsComponent } from './components/tenant-units/tenant-units.component';
import { ModalInvoicesGenericCanceledComponent } from './components/reports/modal-invoices-generic-canceled.component';
import { ReportInvoicesGenericCanceledComponent } from './components/reports/report-invoices-generic-canceled.component';
import { ModalSettlementBillReportComponent } from './components/modals/modal-settlement-bill-report.component';
import { ModalSettlementStatementComponent } from './components/modals/modal-settlement-statement.component';
import { ModalUtilityZoneComponent } from './components/modals/modal-utility-zone.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SettlementComponent,
    UnitComponent,
    SidebarComponent,
    TenantsComponent,
    ServicesComponent,
    AmenitiesComponent,
    ProvidersComponent,
    PaymentsComponent,
    ReceiptsComponent,
    ServiceComponent,
    CargaRecibosComponent,
    UtilitesComponent,
    TenantComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    UsersComponent,
    RolesComponent,
    MaintenancesComponent,
    BillComponent,
    AmenityComponent,
    FormFloorComponent,
    FormUnitComponent,
    ProfileComponent,
    TenantSignInComponent,
    TenantSignUpComponent,
    PaymentComponent,
    TenantProfileComponent,
    TenantBillsComponent,
    TenantBillComponent,
    TenantPaymentsComponent,
    CheckoutComponent,
    TenantPaymentMethodsComponent,
    TenantPaymentMethodComponent,
    TenantDashboardComponent,
    TenantEditPaymentMethodComponent,
    TransactionSuccessComponent,
    ShowBillComponent,
    BillTenantComponent,
    IndexSettlementComponent,
    TanquesComponent,
    TanqueCreateComponent,
    TanqueEditComponent,
    FormTanqueComponent,
    CreateSettlementComponent,
    FilterPipe,
    BuildingFloorsComponent,
    FormTenantComponent,
    ProviderCreateComponent,
    ProviderEditComponent,
    FormProvidersComponent,
    FormAmenityComponent,
    AmenityCreateComponent,
    ServiceCreateComponent,
    FormServiceComponent,
    FormUserComponent,
    RoleCreateComponent,
    RoleEditComponent,
    FormRoleComponent,
    ConfirmPasswordChangeComponent,
    ItemTenantComponent,
    ComercialSettlementsComponent,
    UnpaidComponent,
    ItemServiceComponent,
    ItemTinyTenantComponent,
    ItemTinyUserComponent,
    BillsSettlementComponent,
    TanqueGaugeComponent,
    ItemItemProviderComponent,
    ItemTransactionComponent,
    TechniciansComponent,
    TechnicianEditComponent,
    TechnicianCreateComponent,
    FormTechnicianComponent,
    ItemBillComponent,
    RefuelsComponent,
    OrdersComponent,
    ModalOptionsPayComponent,
    RefuelEditComponent,
    RefuelCreateComponent,
    OrderCreateComponent,
    OrderEditComponent,
    FormRefuelComponent,
    FormOrderComponent,
    InvoicesComponent,
    InvoiceCreateComponent,
    TenantInvoiceComponent,
    TenantInvoiceListComponent,
    ItemPaymentMethodComponent,
    ItemTanqueComponent,
    ItemOrderComponent,
    ReceiptBreakdownComponent,
    PaymentConceptsComponent,
    ConceptEditComponent,
    ConceptCreateComponent,
    FormPaymentConceptComponent,
    ItemConceptComponent,
    ItemFormConceptComponent,
    ReceiptReadsComponent,
    ItemInvoiceComponent,
    BillCommercialComponent,
    TaskCreateComponent,
    TaskEditComponent,
    FormTaskComponent,
    TaskIndexComponent,
    ModalAddBuildingComponent,
    CategoryIndexComponent,
    CategoryCreateComponent,
    CategoryEditComponent,
    FormTaskCategoryComponent,
    BillsTenantsComponent,
    BuildingUnitsComponent,
    UnitDetailsComponent,
    ItemTaskComponent,
    ItemTinyTaskComponent,
    CommercialsComponent,
    CommercialComponent,
    InvoiceShowComponent,
    AmenityCalendarComponent,
    AmenityTasksComponent,
    AmenityTaskCreateComponent,
    AmenityTaskEditComponent,
    InvoiceComplementComponent,
    InvoiceGlobalComponent,
    ItemBillPendingComponent,
    ModalInvoiceComplementComponent,
    ItemSettlementComponent,
    TenantInvoicesComponent,
    TenantInvoiceShowComponent,
    InvoiceCreateGlobalComponent,
    ItemBillInvoiceComponent,
    ProfileIndexComponent,
    ProfileEditComponent,
    ProfileCreateComponent,
    ProfileShowComponent,
    InvoiceDashboardComponent,
    FilterAdvancedComponent,
    InvoiceCreditnoteComponent,
    InvoiceCreditnoteCreateComponent,
    ItemProfileComponent,
    FormProfileComponent,
    ItemInvoiceGlobalComponent,
    ItemInvoiceNotaComponent,
    TenantUsersComponent,
    TenantUserEditComponent,
    FormConceptsComponent,
    ModalInvoiceNotaComponent,
    ItemCommercialComponent,
    FormAbilityComponent,
    FormReadingComponent,
    CurrencyToTextPipe,
    IndexZoneComponent,
    EditZoneComponent,
    CreateZoneComponent,
    FormZoneComponent,
    IndexReportsComponent,
    IndexBalancesComponent,
    GenerateComponent,
    ToolIndexComponent,
    ToolEditComponent,
    ToolCreateComponent,
    FormToolComponent,
    SkillIndexComponent,
    SkillEditComponent,
    SkillCreateComponent,
    VehiclesIndexComponent,
    VehiclesEditComponent,
    VehiclesCreateComponent,
    BuilderIndexComponent,
    BuilderEditComponent,
    BuilderCreateComponent,
    FormSkillComponent,
    FormVehicleComponent,
    FormBuilderComponent,
    ReceiptTinyComponent,
    ItemBalanceComponent,
    MeterIndexComponent,
    MeterEditComponent,
    MeterCreateComponent,
    FormGasMeterComponent,
    ItemGasMeterComponent,
    ItemTinyGasMeterComponent,
    ItemSkillComponent,
    ItemVehicleComponent,
    ItemToolComponent,
    ModalWorkScheduleComponent,
    ModalVehiclesComponent,
    ModalSkillsComponent,
    ModalToolsComponent,
    ActivityIndexComponent,
    ActivityEditComponent,
    ActivityCreateComponent,
    ActivityTypeIndexComponent,
    ActivityTypeEditComponent,
    ActivityTypeCreateComponent,
    FormActivityComponent,
    FormActivityTypeComponent,
    CountUpDirective,
    IndexChartComponent,
    BarChartComponent,
    DashboardSettlementComponent,
    DashboardTechniciansComponent,
    HolidaysComponent,
    ModalHolidayComponent,
    TaskReviewComponent,
    MapComponent,
    ItemRefuelComponent,
    ModalSetPriceComponent,
    InvoiceCanceledComponent,
    ItemInvoiceCanceledComponent,
    ModalInvoiceCancelComponent,
    InvoiceShowGlobalComponent,
    UserCreateComponent,
    ItemTaskTenantComponent,
    CalendarEventComponent,
    ItemPriceComponent,
    VideoPlayerComponent,
    NotificationsComponent,
    UnassignedComponent,
    ItemUnassignedComponent,
    TaskRefuelComponent,
    ModalAsignTaskComponent,
    ModalVehicleAsignComponent,
    DashboardCommercialComponent,
    GaugeChartComponent,
    NotifyFloaterComponent,
    ItemTaskRefuelComponent,
    ModalAsignTaskOperatorComponent,
    SearchTenantsComponent,
    TasksDashboardComponent,
    FilterBasicComponent,
    ModalInvoiceConfigComponent,
    InvoiceUploaderComponent,
    ItemSmTenantComponent,
    ModalTenantComponent,
    ModalGasMeterComponent,
    FilterFullComponent,
    InvoiceGenericComponent,
    ModalBillVerifyComponent,
    ItemInvoiceGenericComponent,
    TransactionFailComponent,
    CreateCustomBillComponent,
    BarChartConsumptionsComponent,
    PieChartPortfolioComponent,
    LineChartConsumptionComponent,
    ProfileDashboardComponent,
    TransactionsPaymentsComponent,
    ModalInventoryReportComponent,
    ReportInvoicesIngresoComponent,
    ReportInvoicesCanceledComponent,
    ReportInventoryComponent,
    ReportInvoicesGlobalComponent,
    ReportInvoicesNotasComponent,
    ReportInvoicesGenericComponent,
    ModalInvoicesGenericComponent,
    ModalInvoicesCanceledComponent,
    ModalInvoicesGlobalComponent,
    ModalInvoicesIngresoComponent,
    ModalInvoicesNotasComponent,
    RefuelShowComponent,
    StlBarChartConsumptionsComponent,
    AnovaLineChartConsumptionsComponent,
    ItemBillPaidComponent,
    FilterSemiComponent,
    ModalGasMeterMaintenanceComponent,
    ContractUnitComponent,
    ContractSettlementComponent,
    ModalAddContractComponent,
    FormContractComponent,
    ItemContractComponent,
    FormPublicRecordComponent,
    SignInInvoiceComponent,
    ItemTenantInvoiceComponent,
    InvoiceAcceptanceComponent,
    ModalSetBillDateComponent,
    ContractIndexComponent,
    ContractEditComponent,
    ContractTypesIndexComponent,
    ContractTypesEditComponent,
    ItemContractTypesComponent,
    FormContractTypesComponent,
    MeterUnitComponent,
    ModalGasMeterMaintenanceRemoveComponent,
    FormTenantFiscalComponent,
    FormTenantAddressComponent,
    FormUserPasswordComponent,
    ModalUserPasswordComponent,
    ModalUserFormComponent,
    TenantUnitsComponent,
    ModalInvoicesGenericCanceledComponent,
    ReportInvoicesGenericCanceledComponent,
    ModalSettlementBillReportComponent,
    ModalSettlementStatementComponent,
    ModalUtilityZoneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxPaginationModule,
    EmojiModule,
    HttpClientModule,
    CommonModule,    
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    // AngularFireStorageModule,
    // AngularFireDatabaseModule,
    FullCalendarModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ColorPickerModule,
    NgxSkeletonLoaderModule,
    ToastrModule.forRoot(),
    PasswordStrengthMeterModule.forRoot(),
    FormsModule,
    BreadcrumbModule,
    DndModule,
  ],
  providers: [Papa, HttpClient, AuthService, BreadcrumbService],
  bootstrap: [AppComponent]
})
export class AppModule { }