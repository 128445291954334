import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';
import { SettlementsService } from '../services/settlements.service';
import { ToastrService } from 'ngx-toastr';

import * as bs from 'bootstrap';

@Component({
  selector: '[app-contract-unit]',
  template: `
    <section >
      <div class="row">
        <div class="col-8">
          <h5 class="mt-2">Contratos de la unidad</h5>
        </div>
        <div class="col-4 text-end" *ngIf="contracts.length > 0">
          <button class="btn btn-outline-primary btn-sm rounded-2" (click)="createContract()"><fa-icon [icon]="icons.faPlus"></fa-icon></button>
        </div>
      </div>
      <div class="row" *ngIf="contracts.length==0">
        <div class="col-12 text-center my-3">
          <div class="list-group">
            <div class="list-group-item pt-5 pb-5">
              <button class="btn btn-outline-primary rounded-5" (click)="createContract()">
                <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
                Agregar contrato
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="contracts.length > 0">        
        <div class="col-12"> 
          <div class="list-group">
            <div app-item-contract
              [contract]="contract"
              [contractTypes]="contractTypes"
              class="list-group-item list-group-item-action"  *ngFor="let contract of contracts | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries}"
            ></div>
          </div>
        </div>
        <div class="col-md-12 text-end mt-3" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="contracts.length>0"
            ></pagination-controls>
          </div>
      </div>
      
        <app-modal-add-contract 
          [contractTypes]="contractTypes"
          [settlement]="settlement"
          [tenants]="tenants"
          [unit]="unit"
          [tanque]="tanque"
          [profile]="profile"
        ></app-modal-add-contract>
      
    </section>
    <!--
    STL ==> {{settlement | json}}
    UNIT => {{unit | json}}
    TANK => {{tanque | json}}
    -->
  `,
  styles: [
  ]
})
export class ContractUnitComponent implements AfterViewInit {
  @Input() settlement: any;
  @Input() unit: any;
  @Input() tanque: any;
  @Input() tenants: any;
  
  
  contracts: any[] = [];
  meta: any;
  p: number = 1;

  profile: any;

  payload: any = {
    page: 1,
    per_page: 5,
  };

  contractTypes: any[] = [];

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile') || '{}').profile;
  }

  ngAfterViewInit(): void {
    this.store.getContractTypes({ is_active: true }).subscribe((data:any) => {
      console.log('load CONTRACT TYPES => ', data);
      this.contractTypes = data.data;
    });

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unit']&&changes['unit'].currentValue) {
      console.log('UNIT ====> ', changes['unit'].currentValue);
      if (changes['unit'].currentValue.id) {
        this.loadContracts();
      }
    }
    if (changes['contracts']&&changes['contracts'].currentValue) {
      // console.log('CONTRACTS ====> ', changes['contracts'].currentValue);
    }
    if (changes['settlement']&&changes['settlement'].currentValue) {
      // console.log('STLS ====> ', changes['settlement'].currentValue);
    }
    if (changes['tenants']&&changes['tenants'].currentValue) {
      console.log('changes contract unit TENANTS ====> ', changes['tenants'].currentValue);
    }
  }

  loadContracts(): void {
    this.payload.unit_id = this.unit.id;
    this.store.getContracts(this.payload).subscribe({
      next: (data: any) => {
        // if (!data) return;
        this.contracts = data.data;
        this.meta = data.meta;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('No se pudieron cargar los contratos', 'Error');
      }
    });
  }

  protected createContract(): void {
    console.log('createContract');    
    new bs.Modal(<HTMLInputElement>document.getElementById('modalAddContract')).show();
  }

  pageChanged(event: any): void {
    this.payload.page = event;
    this.loadContracts();
  }

}
